export const displayPercentage = (number: number): string => {
	if (typeof number === 'number') {
		return number.toLocaleString('fr-FR', { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 });
	}
	return '';
};

export const displayCapitalized = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const displayDateInLetters = (date: string): string =>
	new Date(date).toLocaleString('fr-FR', {
		month: 'short',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
	});

export const displayDateSimpleFormat = (date: string | Date): string =>
	new Date(date).toLocaleString('fr-FR', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	});
