import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OfferSystemFiltersDto, OfferSystemPaginationDto } from 'services/ops/offers';

interface OffersToolState {
	filters: OfferSystemFiltersDto;
	pagination: OfferSystemPaginationDto;
}

const initialState: OffersToolState = {
	filters: {
		includeProductType: [],
		includeOfferId: [],
		emailClient: '',
		showPaid: false,
	},
	pagination: {
		page: 1,
		limit: 25,
	},
};

const offersToolSlice = createSlice({
	name: 'offers-tool',
	initialState,
	reducers: {
		setOffersFilters(state, action: PayloadAction<OfferSystemFiltersDto>) {
			state.filters = action.payload;
		},
		resetOffersFilters(state) {
			state.filters = { ...initialState.filters };
		},
		setOffersPagination(state, action: PayloadAction<OfferSystemPaginationDto>) {
			state.pagination = action.payload;
		},
	},
});

export const { setOffersFilters, resetOffersFilters, setOffersPagination } = offersToolSlice.actions;

export default offersToolSlice.reducer;
