import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AddIcon, ChevronDownIcon, ChevronRightIcon, CloseIcon, MinusIcon } from '@chakra-ui/icons';
import {
	Box,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	Button,
	Center,
	Heading,
	HStack,
	IconButton,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Select,
	Skeleton,
	Text,
	useDisclosure,
	VStack,
	Wrap,
} from '@chakra-ui/react';

import AlertDialog from 'components/AlertDialog';
import Cardlayout from 'components/CardLayout';
import { CommentsSection, useOpsComments } from 'components/Comment';
import HistorySection from 'components/History';
import { ChangeBIOverdueDate } from 'features/ChangeOverdueDate';
import MailFollowUpSection from 'features/mailing-framework/MailFollowUpSection';
import UserDataTable from 'features/UserDataTable';
import useAssignedOps, { NO_ASSIGNED_OPS, SelectAssignedOps } from 'hooks/useAssignedOps';
import useThemedToast from 'hooks/useThemedToast';
import BlockingInstanceNoDocsReview from 'pages/ops/super/blocking-instance/review/BlockingInstanceNoDocsReview';
import BlockingInstanceReview from 'pages/ops/super/blocking-instance/review/BlockingInstanceReview';
import { useGetClientKYCQuery } from 'services/client';
import { useUploadDocumentMutation } from 'services/document';
import {
	UpdateMissingDocument,
	useDeleteBlockingInstanceMutation,
	useGetBlockingInstanceByIdQuery,
	useRemoveBlockingInstanceRelancesMutation,
	useUpdateBlockingInstancesMutation,
} from 'services/ops/blocking-instance';
import { BlockingInstanceStatus } from 'types/blocking-instance.type';
import { documentDataMap, DocumentName } from 'utils/documentNamingMap';
import { isNone, isNotNone } from 'utils/functions';
import { PermissionDisplayGuard } from 'utils/guards';
import { BoPermission } from 'utils/permissions';

export const BlockingInstanceDetails = () => {
	const toast = useThemedToast();
	const navigate = useNavigate();
	const { id } = useParams<{ id: string }>();
	const inputFile = useRef<HTMLInputElement | null>(null);

	const { isOpen: isDeleteDialogOpen, onOpen: openDeleteDialog, onClose: closeDeleteDialog } = useDisclosure();

	const { data: bi, isFetching: isBiFetching } = useGetBlockingInstanceByIdQuery(
		{ id: id ?? '' },
		{ skip: !isNotNone(id) },
	);
	const { data: kyc } = useGetClientKYCQuery({ email: bi?.user?.email ?? '' }, { skip: isNone(bi?.user) });

	const [updateBI, { isLoading: isUpdateLoading }] = useUpdateBlockingInstancesMutation();
	const [deleteBI, { isLoading: isDeleteLoading }] = useDeleteBlockingInstanceMutation();
	const [uploadDocument, { isLoading: isUploadLoading }] = useUploadDocumentMutation();
	const [removeInstanceRelances] = useRemoveBlockingInstanceRelancesMutation();

	const comments = useOpsComments();
	const { assignedOps, onChangeAssignedOps } = useAssignedOps(NO_ASSIGNED_OPS);

	const [missingDocuments, setMissingDococuments] = useState<UpdateMissingDocument[]>([]);
	const [selectedDocuments, setSelectedDocuments] = useState(DocumentName.JUSTIFICATIF_DOMICILE);
	const [status, setStatus] = useState<BlockingInstanceStatus | undefined>(undefined);

	const opsProperties = useMemo(() => bi?.opsProperties, [bi?.opsProperties]);

	const dirtyFields = useMemo(() => {
		const isStatusDirty = status !== bi?.status;
		const isAssignedOpsDirty = opsProperties?.assignedOpsEmail !== assignedOps && assignedOps !== NO_ASSIGNED_OPS;
		const isDocumentsDirty = !(
			bi?.missingDocuments.every((doc) =>
				missingDocuments.some((d) => d.documentName === doc.documentName && d.submitted === doc.submitted),
			) &&
			missingDocuments.every((doc) =>
				bi?.missingDocuments.some((d) => d.documentName === doc.documentName && d.submitted === doc.submitted),
			)
		);
		return { isStatusDirty, isAssignedOpsDirty, isDocumentsDirty };
	}, [assignedOps, bi?.missingDocuments, bi?.status, missingDocuments, opsProperties?.assignedOpsEmail, status]);

	const handleToggleDocuments = useCallback(
		(doc: UpdateMissingDocument) => {
			const docs = [...missingDocuments];
			const index = docs.findIndex((d) => d.documentName === doc.documentName);
			docs[index].submitted = !docs[index].submitted;
			setMissingDococuments(docs);
		},
		[missingDocuments],
	);

	const handleUpdate = useCallback(() => {
		updateBI({
			id: bi!.id,
			missingDocuments,
			status: dirtyFields.isStatusDirty ? status : undefined,
			properties: {
				id: bi!.opsPropertiesId,
				assignedOpsEmail: dirtyFields.isAssignedOpsDirty ? assignedOps : undefined,
			},
		})
			.unwrap()
			.then(() => {
				setStatus(undefined);
				toast({ status: 'success', title: "Statut de l'instance mise à jour avec succès" });
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
		// eslint-disable-next-line prettier/prettier
	}, [updateBI, bi, missingDocuments, dirtyFields.isStatusDirty, dirtyFields.isAssignedOpsDirty, status, assignedOps, toast]);

	const handleDelete = useCallback(() => {
		deleteBI({ id: bi!.id })
			.unwrap()
			.then(() => {
				closeDeleteDialog();
				navigate('..');
				toast({ status: 'success', title: 'Instance supprimée avec succès' });
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	}, [bi, closeDeleteDialog, deleteBI, navigate, toast]);

	const handleSendComment = useCallback(() => {
		// If the transfer has no opsPropertiesId, we need to generate it before sending the comment
		if (!bi?.opsPropertiesId) {
			updateBI({
				id: bi!.id,
				properties: { id: bi!.opsPropertiesId, comment: comments.comment },
			});
			comments.onChangeComment('');
		} else comments.onCreateComment(bi.opsPropertiesId!);
	}, [comments, bi, updateBI]);

	const handleAddMissingDocument = useCallback(
		({ files, document }: { files: FileList; document: UpdateMissingDocument }) => {
			if (!bi || !bi.userId || !bi.linkedEntityId || !document.id) return;

			const formData = new FormData();

			formData.append('documentName', document.documentName);
			formData.append('userId', bi.userId);
			Array.from(files).forEach((file) => formData.append('files', file));
			formData.append('blockingInstanceDocumentId', document.id);
			formData.append('subscriptionId', bi.linkedEntityId);
			formData.append('productType', bi.product);
			if (isNotNone(bi.linkedEntity)) {
				if ('moralPersonId' in bi.linkedEntity && bi.linkedEntity.moralPersonId)
					formData.append('moralPersonId', bi.linkedEntity.moralPersonId);
				if (bi.linkedEntity.bankInformationId) formData.append('bankInformationId', bi.linkedEntity.bankInformationId);
			}

			uploadDocument(formData)
				.unwrap()
				.then(() =>
					setMissingDococuments((prev) => prev.map((d) => (d.id === document.id ? { ...d, submitted: true } : d))),
				)
				.catch((e) => toast({ title: 'Erreur', description: e.data.message, status: 'error' }))
				.finally(() => {
					inputFile.current!.value = '';
				});
		},
		[bi, toast, uploadDocument],
	);

	const handleRemoveInstanceRelances = useCallback(
		() =>
			removeInstanceRelances({ id: bi!.id })
				.unwrap()
				.catch((e) => toast({ status: 'error', title: 'Erreur', description: e.data.message })),
		[bi, removeInstanceRelances, toast],
	);

	useEffect(() => {
		if (bi?.status) setStatus(bi.status);
		if (bi?.opsProperties?.assignedOpsEmail) onChangeAssignedOps(bi.opsProperties?.assignedOpsEmail);
		if (bi?.missingDocuments) {
			const docs =
				bi.missingDocuments?.map((doc) => ({ id: doc.id, documentName: doc.documentName, submitted: doc.submitted })) ??
				[];
			setMissingDococuments(docs);
		}
	}, [bi, onChangeAssignedOps]);

	useEffect(() => {
		if (bi?.status) setStatus(bi.status);
		if (isNotNone(opsProperties?.assignedOpsEmail)) onChangeAssignedOps(opsProperties?.assignedOpsEmail);
	}, [bi, onChangeAssignedOps, opsProperties?.assignedOpsEmail]);

	if (isBiFetching) return <Skeleton h="100%" w="100%" />;
	if (isNone(bi))
		return (
			<Center>
				<Heading size="md">Instance non trouvée</Heading>
			</Center>
		);

	return (
		<VStack w="100%" spacing="12px" align="start" pb="32px">
			<Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
				<BreadcrumbItem>
					<BreadcrumbLink onClick={() => navigate('..')}>Instances Bloquantes</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink>{bi.user?.email}</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<HStack w="100%" align="start" justify="space-between">
				<Heading size="lg">
					{kyc?.kyc?.firstName} {kyc?.kyc?.lastName} - {bi.product}
				</Heading>

				<HStack align="start">
					<Button
						isDisabled={Object.values(dirtyFields).every((i) => !i)}
						colorScheme="blue"
						onClick={handleUpdate}
						isLoading={isUpdateLoading}
					>
						Enregistrer les modifications
					</Button>

					<Menu closeOnSelect={false}>
						<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
							Actions
						</MenuButton>
						<MenuList>
							<MenuItem onClick={() => navigate(`/ops/super/client/${bi.user.id}`)}>Page client</MenuItem>
							<MenuItem onClick={handleRemoveInstanceRelances}>Retirer du workflow hubspot</MenuItem>
							<PermissionDisplayGuard permission={BoPermission.BLOCKING_INSTANCE_DELETE}>
								<MenuItem color="red.600" onClick={openDeleteDialog}>
									Supprimer l'instance bloquante
								</MenuItem>
							</PermissionDisplayGuard>
						</MenuList>
					</Menu>
				</HStack>
			</HStack>

			<HStack w="100%" align="start" spacing="12px" justify="space-between">
				<VStack spacing="12px" align="start" flex={3}>
					{bi.userId &&
						(bi.status === BlockingInstanceStatus.DOCUMENTS_SUBMITTED ||
							(bi.status === BlockingInstanceStatus.SENT_TO_CLIENT && bi.missingDocuments.length === 0)) && (
							<Cardlayout title="Review des documents">
								{bi.status === BlockingInstanceStatus.DOCUMENTS_SUBMITTED && (
									<BlockingInstanceReview blockingInstance={bi} />
								)}
								{bi.status === BlockingInstanceStatus.SENT_TO_CLIENT && bi.missingDocuments.length === 0 && (
									<BlockingInstanceNoDocsReview blockingInstance={bi} />
								)}
							</Cardlayout>
						)}

					{isNotNone(bi.user) && <UserDataTable email={bi.user.email} />}

					<MailFollowUpSection conversationId={bi?.conversation?.id} />

					<CommentsSection
						comment={comments.comment}
						commentList={opsProperties?.comments}
						onCreateComment={handleSendComment}
						onChangeComment={comments.onChangeComment}
						onDeleteComment={comments.onDeleteComment}
						isLoading={isUpdateLoading}
					/>

					<HistorySection opsProperties={opsProperties} />
				</VStack>

				<VStack spacing="12px" align="start" flex={1}>
					<Cardlayout title="Mettre à jour">
						<VStack align="start" w="100%" spacing="12px">
							<SelectAssignedOps
								isLoading={isUpdateLoading}
								allowNone
								assignedOps={assignedOps}
								onChangeAssignedOps={onChangeAssignedOps}
							/>
							<VStack align="start" w="100%">
								<Text>Status</Text>
								<Select value={status} onChange={(e) => setStatus(e.target.value as BlockingInstanceStatus)}>
									{Object.values(BlockingInstanceStatus)?.map((s) => (
										<option key={s} value={s}>
											{s}
										</option>
									))}
								</Select>
							</VStack>

							<VStack align="start">
								<Text>Documents manquants</Text>
								<HStack w="100%">
									<Select
										value={selectedDocuments}
										onChange={(e) => setSelectedDocuments(e.target.value as DocumentName)}
									>
										{Object.entries(documentDataMap)
											.filter(([docKey]) => !missingDocuments.some((missingDoc) => missingDoc.documentName === docKey))
											.sort((a, b) => a[1].displayName.localeCompare(b[1].displayName))
											.map(([key, value]) => (
												<option key={key} value={key}>
													{value.displayName}
												</option>
											))}
									</Select>
									<Button
										onClick={() =>
											setMissingDococuments([
												...missingDocuments,
												{ documentName: selectedDocuments, submitted: false },
											])
										}
									>
										Ajouter
									</Button>
								</HStack>

								<VStack mt="12px" align="start" flex="1">
									<Wrap shouldWrapChildren w="100%" align="start" p="4px" spacing="16px">
										{missingDocuments.map((doc) => (
											<VStack key={doc.documentName} spacing="0px" borderRadius="4px" overflow="hidden">
												<HStack w="100%" align="center" bg="gray.50" px="16px" py="4px" justify="space-between">
													<Text>{documentDataMap[doc.documentName]?.displayName ?? doc.documentName}</Text>
													<CloseIcon
														cursor="pointer"
														boxSize="12px"
														onClick={() => setMissingDococuments(missingDocuments.filter((d) => d !== doc))}
													/>
												</HStack>
												<HStack
													w="100%"
													align="center"
													bg={doc.submitted ? 'green.400' : 'red.400'}
													px="16px"
													py="4px"
													justify="center"
													userSelect="none"
												>
													<HStack color="white" w="100%" justify="space-between">
														{doc.submitted ? (
															<>
																<Text color="white">Uploadé</Text>
																<HStack spacing="3px">
																	<IconButton
																		size="xs"
																		aria-label="toggle"
																		bg="green.500"
																		_hover={{ bg: 'green.600' }}
																		icon={<MinusIcon />}
																		color="white"
																		onClick={() => handleToggleDocuments(doc)}
																	/>
																</HStack>
															</>
														) : (
															<>
																<Text>Manquant</Text>
																{'id' in doc && doc.id && (
																	<HStack spacing="3px">
																		<Input
																			type="file"
																			ref={inputFile}
																			multiple
																			display="none"
																			onChange={(e) =>
																				handleAddMissingDocument({ files: e.target.files!, document: doc })
																			}
																		/>
																		<IconButton
																			size="xs"
																			aria-label="upload"
																			bg="red.500"
																			_hover={{ bg: 'red.600' }}
																			color="white"
																			icon={<AddIcon />}
																			isLoading={isUploadLoading}
																			onClick={() => inputFile.current?.click()}
																		/>
																	</HStack>
																)}
															</>
														)}
													</HStack>
												</HStack>
											</VStack>
										))}
									</Wrap>
								</VStack>
							</VStack>
						</VStack>
					</Cardlayout>

					<Cardlayout title="Deadline">
						<ChangeBIOverdueDate overdueItem={bi} />
					</Cardlayout>
				</VStack>
			</HStack>

			<AlertDialog
				isOpen={isDeleteDialogOpen}
				onClose={closeDeleteDialog}
				header="Supprimer cette instance bloquante"
				body={<Text>Vous êtes sur le point de supprimer cette instance bloquante.</Text>}
				footer={
					<HStack w="100%" justify="space-between">
						<Button onClick={closeDeleteDialog}>Annuler</Button>
						<Box>
							<Button colorScheme="red" ml={3} isLoading={isDeleteLoading} onClick={handleDelete}>
								Supprimer
							</Button>
						</Box>
					</HStack>
				}
			/>
		</VStack>
	);
};
