import { FC } from 'react';
import { InfoIcon } from '@chakra-ui/icons';
import { Badge, HStack, Text, Th, Tooltip } from '@chakra-ui/react';

const KpiDurationCell: FC<{ duration: number; durationDisplay: string; durationDisplayHours: string }> = ({
	duration,
	durationDisplay,
	durationDisplayHours,
}) =>
	duration === null ? (
		<Tooltip label="S'il n'y a pas de durée, cela veut dire qu'il manque une des deux dates requises pour cacluler la durée.">
			<Badge colorScheme="yellow">Pas de durée</Badge>
		</Tooltip>
	) : (
		<Tooltip label={`${durationDisplay} jours`}>
			<Text>{durationDisplayHours} h</Text>
		</Tooltip>
	);

const KpiDealHeaders: FC = () => (
	<>
		<Th>Email</Th>
		<Th>Produit</Th>
		<Th>Soumis le</Th>
		<Th>Traité le</Th>
		<Th>Complété le</Th>
		<Th>Durée de traitement (h)</Th>
		<Th>Durée de completion (h)</Th>
		<Th>Black</Th>
		<Th>Instance</Th>
	</>
);

const KpiBlockingInstanceHeaders: FC<{ isNotMissingInfo: boolean }> = ({ isNotMissingInfo }) => (
	<>
		<Th>Email</Th>
		{isNotMissingInfo && (
			<Th>
				<Text>opened</Text>
			</Th>
		)}
		<Th>
			<Text>sent To Client</Text>
		</Th>
		<Th>
			<Text>documents Submitted</Text>
		</Th>
		{isNotMissingInfo && (
			<Th>
				<Text>sent To Partner</Text>
			</Th>
		)}
		<Th>
			<Text>closed</Text>
		</Th>
		{isNotMissingInfo && (
			<Th>
				<Tooltip label="De OPEN à SENT TO CLIENT">
					<HStack>
						<Text>Durée envoi client (h)</Text>
						<InfoIcon />
					</HStack>
				</Tooltip>
			</Th>
		)}
		<Th>
			<Tooltip label="De SENT TO CLIENT à DOCUMENTS SUBMITTED">
				<HStack>
					<Text>Durée envoi document (h)</Text>
					<InfoIcon />
				</HStack>
			</Tooltip>
		</Th>
		{isNotMissingInfo && (
			<Th>
				<Tooltip label="De DOCUMENTS SUBMITTED à SENT TO PARTNER">
					<HStack>
						<Text>Durée envoi partenaire (h)</Text>
						<InfoIcon />
					</HStack>
				</Tooltip>
			</Th>
		)}
		<Th>
			<Tooltip label="De DOCUMENTS SUBMITTED à CLOSED (Blocking Info)">
				<HStack>
					<Text>Durée closed</Text>
					<InfoIcon />
				</HStack>
			</Tooltip>
		</Th>
		<Th>Black</Th>
	</>
);

export { KpiBlockingInstanceHeaders, KpiDealHeaders, KpiDurationCell };
