import { CloseIcon } from '@chakra-ui/icons';
import { Button, HStack, Input, Text, VStack } from '@chakra-ui/react';

import CheckboxFilter from 'components/filters/Checkbox';
import DropDownOptionPicker from 'components/filters/Dropdown/DropDownOptionPicker';
import { OfferSystemFiltersDto, useGetOffersQuery } from 'services/ops/offers';
import { useAppDispatch, useAppSelector } from 'store';
import { ProductType } from 'types/global.type';

import { resetOffersFilters, setOffersFilters } from './offers.slice';

const OffersFilters = () => {
	const { data: offers } = useGetOffersQuery();
	const filters = useAppSelector((state) => state.offersTool.filters);
	const {
		includeProductType: productTypeFilter,
		includeOfferId: offerFilter,
		emailClient: emailInput,
		showPaid,
	} = filters;
	const dispatch = useAppDispatch();

	const setInput = (value: string) => {
		dispatch(setOffersFilters({ ...filters, emailClient: value }));
	};

	const handleFilterChange = (key: keyof OfferSystemFiltersDto, value: string[]) => {
		dispatch(setOffersFilters({ ...filters, [key]: value }));
	};

	const handleOfferFilterChange = (values: string[]) => {
		const value = values.map((v) => v.split(' ')[0]);
		dispatch(setOffersFilters({ ...filters, includeOfferId: value }));
	};

	const handleCheckboxChange = (key: keyof OfferSystemFiltersDto, value: boolean) => {
		dispatch(setOffersFilters({ ...filters, [key]: value }));
	};

	const handleResetFilters = () => {
		dispatch(resetOffersFilters());
	};

	return (
		<VStack w="100%" spacing="24px" align="start">
			<HStack w="100%" spacing="8px">
				<Input
					value={emailInput}
					bg="white"
					onChange={(e) => setInput(e.target.value.trim())}
					placeholder="Email"
					w="456px"
				/>
				<DropDownOptionPicker
					label="Produit"
					values={productTypeFilter || []}
					onChange={(newValue) => handleFilterChange('includeProductType', newValue)}
					options={Object.values(ProductType)}
				/>

				<DropDownOptionPicker
					label="Offres"
					values={
						offerFilter?.map((o) => {
							const offer = offers?.find((a) => a.id === o);
							return `${offer?.id} ${offer?.offerName}`;
						}) || []
					}
					onChange={(newValue) => handleOfferFilterChange(newValue)}
					options={offers?.map((o) => `${o?.id} ${o?.offerName}`) || []}
				/>

				<CheckboxFilter
					isChecked={showPaid}
					onChange={(event) => handleCheckboxChange('showPaid', event.target.checked)}
				>
					<Text>Afficher les offres remboursées</Text>
				</CheckboxFilter>

				<Button size="md" onClick={handleResetFilters}>
					<HStack spacing="12px">
						<CloseIcon boxSize={3} />
						<Text>Effacer les filtres</Text>
					</HStack>
				</Button>
			</HStack>
		</VStack>
	);
};

export default OffersFilters;
