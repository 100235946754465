import { BaseQueryApi, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { RootState } from 'store';
import { ramifyStacks } from 'utils/constants';

import { setFrontEggToken } from './auth.slice';

const prepareHeaders = (
	headers: Headers,
	{ getState }: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>,
) => {
	const { frontEggToken } = (getState() as RootState).auth;

	if (frontEggToken) headers.set('Authorization', `Bearer ${frontEggToken}`);
	return headers;
};

const baseQuery = fetchBaseQuery({
	baseUrl: ramifyStacks.dashboardBackUrl.toString(),
	prepareHeaders,
});

export const dashboardApi = createApi({
	baseQuery: async (args, api, extraOptions) => {
		const result = await baseQuery(args, api, extraOptions);
		if (result.error?.status === 401) api.dispatch(setFrontEggToken(null));
		return result;
	},
	endpoints: () => ({}),
	reducerPath: 'dashboardOpsApi',
	tagTypes: [
		// shared
		'MAILING_FRAMEWORK',

		// ops
		'OPS_SUBSCRIPTIONS',
		'OPS_CLIENTS',
		'OPS_DOCUMENTS',
		'OPS_DEALS',
		'OPS_PROPERTIES',
		'OPS_TRANSFERS',
		'OPS_BLOCKING_INSTANCE',
		'OPS_BLOCKING_INSTANCE_DOCUMENT',
		'OPS_ALERTS_SYSTEM',
		'OPS_AFFILIATE_REWARDS',
		'OPS_OFFERS',
		'OPS_PROMOTIONS',

		// cpm
		'PROPALES',

		// qis
		'CONTRACT_REBALANCE',

		// phoenix
		'PHOENIX_USERS',
	],
});
