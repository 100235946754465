import { SearchBy } from 'components/filters/SearchBy';
import useSessionStorageState from 'hooks/useSessionStorate';
import { SubscriptionStatus } from 'types/global.type';

export const productOptions = [
	'INVEST_SUB',
	'INVEST_CON',
	'SP',
	'PE',
	'SCPI',
	'CER',
	'CATPM',
	'CATPP',
	'ART',
	'CROWDFUNDING',
	'AV_LUX',
	'GIRARDIN',
];

const initialState = {
	partnerFilter: [],
	statusFilter: Object.values(SubscriptionStatus).filter((s) => s !== SubscriptionStatus.PARTNER_TREATMENT),
	clientTypeFilter: [],
	opsFilter: [],
	onlyOverdue: false,
	onlyCallToDo: false,
	includeDealsWithBI: true,
	productFilter: productOptions,
	input: '',
	searchBy: 'email' as const,
};
const useDealFilters = (context: string, customSearchBy?: SearchBy, customInput?: string) => {
	const [input, setInput] = useSessionStorageState(`${context}_DEAL_SEARCH_INPUT`, customInput ?? initialState.input);
	const [searchBy, setSearchBy] = useSessionStorageState<SearchBy>(
		`${context}_DEAL_SEARCH_BY`,
		customSearchBy ?? initialState.searchBy,
	);
	const [clientTypeFilter, setClientTypeFilter] = useSessionStorageState<string[]>(
		`${context}_DEAL_CLIENT_TYPE_FILTER`,
		initialState.clientTypeFilter,
	);
	const [includeDealsWithBI, setIncludeDealsWithBI] = useSessionStorageState<boolean>(
		`${context}_DEAL_INCLUDE_DEALS_WITH_BI`,
		initialState.includeDealsWithBI,
	);
	const [onlyCallToDo, setOnlyCallToDo] = useSessionStorageState<boolean>(
		`${context}_DEAL_ONLY_CALL_TO_DO`,
		initialState.onlyCallToDo,
	);
	const [onlyOverdue, setOnlyOverdue] = useSessionStorageState<boolean>(
		`${context}_DEAL_ONLY_OVERDUE_DEALS`,
		initialState.onlyOverdue,
	);
	const [statusFilter, setStatusFilter] = useSessionStorageState<SubscriptionStatus[]>(
		`${context}_DEAL_STATUS_FILTER`,
		initialState.statusFilter,
	);
	const [productFilter, setProductFilter] = useSessionStorageState<string[]>(
		`${context}_DEAL_PRODUCT_FILTER`,
		initialState.productFilter,
	);
	const [partnerFilter, setPartnerFilter] = useSessionStorageState<string[]>(
		`${context}_DEAL_PARTNER_FILTER`,
		initialState.partnerFilter,
	);
	const [opsFilter, setOpsFilter] = useSessionStorageState<string[]>(
		`${context}_DEAL_OPS_FILTER`,
		initialState.opsFilter,
	);

	const resetFilters = () => {
		setInput(initialState.input);
		setSearchBy(initialState.searchBy);
		setProductFilter(initialState.productFilter);
		setStatusFilter(initialState.statusFilter);
		setClientTypeFilter(initialState.clientTypeFilter);
		setPartnerFilter(initialState.partnerFilter);
		setOpsFilter(initialState.opsFilter);
		setOnlyOverdue(initialState.onlyOverdue);
		setOnlyCallToDo(initialState.onlyCallToDo);
		setIncludeDealsWithBI(initialState.includeDealsWithBI);
	};

	return {
		partnerFilter,
		setPartnerFilter,
		statusFilter,
		setStatusFilter,
		clientTypeFilter,
		setClientTypeFilter,
		opsFilter,
		setOpsFilter,
		onlyOverdue,
		setOnlyOverdue,
		onlyCallToDo,
		setOnlyCallToDo,
		includeDealsWithBI,
		setIncludeDealsWithBI,
		productFilter,
		setProductFilter,
		input,
		setInput,
		searchBy,
		setSearchBy,
		resetFilters,
	};
};

export default useDealFilters;
