import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { FronteggProvider } from '@frontegg/react';

import AppLayout from 'layout/AppLayout';
import Home from 'pages';
import FiscalityPropale from 'pages/cpm/fiscality-propale';
import ProductPropale from 'pages/cpm/product-propale';
import CpmSuperToolRouter from 'pages/cpm/super';
import AlertsRouter from 'pages/ops/alerts';
import { OpsKpiRouter } from 'pages/ops/kpi';
import OffersTool from 'pages/ops/offers';
import RattachementSubscription from 'pages/ops/rattachement';
import SponsorshipTool from 'pages/ops/sponsorship';
import OpsSuperToolRouter from 'pages/ops/super';
import CreatePhoenixClient from 'pages/phoenix';
import AutologinQIS from 'pages/qis/autologin';
import Rebalancing from 'pages/qis/rebalancing';
import { store } from 'store';
import { fronteggConstants } from 'utils/constants';
import { AuthenticatedGuard, PermissionGuard } from 'utils/guards';
import { BoPermission } from 'utils/permissions';

const AppRouter = () => (
	<RouterProvider
		router={createBrowserRouter(
			createRoutesFromElements(
				<Route
					path="/*"
					element={
						<FronteggProvider
							hostedLoginBox={false}
							contextOptions={{ baseUrl: fronteggConstants.baseUrl }}
							authOptions={{ keepSessionAlive: true }}
							entitlementsOptions={{ enabled: true }}
						>
							<Outlet />
						</FronteggProvider>
					}
				>
					<Route element={<AuthenticatedGuard />}>
						<Route element={<AppLayout />}>
							<Route index element={<Home />} />

							<Route element={<PermissionGuard permission={BoPermission.CLIENT_READ} />}>
								<Route path="ops/super/*" element={<OpsSuperToolRouter />} />
								<Route path="ops/rattachement-subscriptions" element={<RattachementSubscription />} />
								<Route path="ops/alertes/*" element={<AlertsRouter />} />
								<Route path="ops/kpi/*" element={<OpsKpiRouter />} />
								<Route path="ops/sponsorship" element={<SponsorshipTool />} />
								<Route path="ops/offers" element={<OffersTool />} />
							</Route>

							<Route element={<PermissionGuard permission={BoPermission.PROPALE_READ} />}>
								<Route path="cpm/propale-fiscalite" element={<FiscalityPropale />} />
								<Route path="cpm/propale-produit" element={<ProductPropale />} />
								<Route path="cpm/super/*" element={<CpmSuperToolRouter />} />
							</Route>

							<Route element={<PermissionGuard permission={BoPermission.REBALANCING_READ} />}>
								<Route path="qis/rebalancing" element={<Rebalancing />} />
								<Route path="qis/autologin" element={<AutologinQIS />} />
							</Route>

							<Route element={<PermissionGuard permission={BoPermission.PHOENIX_READ} />}>
								<Route path="phoenix/create-account" element={<CreatePhoenixClient />} />
							</Route>
						</Route>
					</Route>
				</Route>,
			),
		)}
	/>
);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<ChakraProvider resetCSS>
			<Provider store={store}>
				<AppRouter />
			</Provider>
		</ChakraProvider>
	</React.StrictMode>,
);
