import { useMemo } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Box,
	Tab,
	Table,
	TableContainer,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Tbody,
	Text,
	Thead,
	Tr,
} from '@chakra-ui/react';

import { ConditionalLink } from 'components/tables/columns/ConditionalLink';
import { BoolTd, DateTd, DefaultTd } from 'components/tables/columns/Td';
import { isBIOverdue } from 'features/ChangeOverdueDate';
import useSessionStorageState from 'hooks/useSessionStorate';
import { BlockingInstanceWithKyc } from 'services/ops/blocking-instance';
import { BlockingInstance, BlockingInstanceLinkedEntity, BlockingInstanceStatus } from 'types/blocking-instance.type';
import { BOContext, ProductType } from 'types/global.type';
import { displayMoney, getUpdatedStatusTime } from 'utils/functions';

import { DefaultTh, SortTh } from './columns/Th';

const getProductAmount = (
	linkedEntity: BlockingInstanceLinkedEntity | undefined,
	productType: ProductType,
): number | undefined => {
	if (!linkedEntity) return;
	if (productType === ProductType.INVEST && 'amount' in linkedEntity) return +linkedEntity.amount;
	if (
		(productType === ProductType.SCPI ||
			productType === ProductType.PE ||
			productType === ProductType.CROWDFUNDING ||
			productType === ProductType.INVEST ||
			productType === ProductType.ART ||
			productType === ProductType.CASH) &&
		'initialDepositAmount' in linkedEntity
	)
		return linkedEntity.initialDepositAmount ? +linkedEntity.initialDepositAmount : undefined;

	return undefined;
};

const getDateDistance = (date: string | undefined) => {
	if (!date) return '?';
	const then = new Date(date);
	const now = new Date();
	const diff = now.getTime() - then.getTime();
	const days = Math.floor((diff / (1000 * 60 * 60 * 24)) * 10) / 10;

	return days;
};

const rowColor = (bi: BlockingInstance, selectedBi?: BlockingInstance): string => {
	if (selectedBi?.id === bi.id) return 'blue.50';
	if (isBIOverdue(bi)) return 'red.50';
	return 'white';
};

const sortFields = (a: BlockingInstanceWithKyc, b: BlockingInstanceWithKyc, orderBy: string, order: 'asc' | 'desc') => {
	if (orderBy === 'createdAt') {
		if (order === 'asc') return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
		else return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
	}
	if (orderBy === 'updatedAt') {
		if (order === 'asc') return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
		else return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
	}
	if (orderBy === 'statusUpdatedAt') {
		const aStatusUpdatedAt = getUpdatedStatusTime(a)?.getTime() ?? 0;
		const bStatusUpdatedAt = getUpdatedStatusTime(b)?.getTime() ?? 0;
		if (order === 'asc') return aStatusUpdatedAt - bStatusUpdatedAt;
		else return bStatusUpdatedAt - aStatusUpdatedAt;
	}
	if (orderBy === 'sentToClientAt') {
		const asentToClientAt = a.sentToClientAt ? new Date(a.sentToClientAt).getTime() : new Date().getTime();
		const bsentToClientAt = b.sentToClientAt ? new Date(b.sentToClientAt).getTime() : new Date().getTime();
		if (order === 'asc') return asentToClientAt - bsentToClientAt;
		else return bsentToClientAt - asentToClientAt;
	}
	if (orderBy === 'amount') {
		const aAmount = getProductAmount(a.linkedEntity, a.product);
		const bAmount = getProductAmount(b.linkedEntity, b.product);
		if (order === 'asc') return (aAmount || 0) - (bAmount || 0);
		else return (bAmount || 0) - (aAmount || 0);
	}
	return 0;
};

type BlockingInstancesTableProps = {
	context: Extract<BOContext, 'client' | 'blocking-instance'>;
	blockingInstances: BlockingInstanceWithKyc[];
	selectedBlockingInstances?: BlockingInstanceWithKyc;
	onClick: (c: BlockingInstanceWithKyc) => void;
	pageSize: number;
};

const initialState = {
	pageIndex: 0,
	selectedStatus: 'ALL' as const,
	sortBy: 'updatedAt',
	sortDirection: 'desc' as const,
};

const BlockingInstancesTable = ({
	context,
	blockingInstances,
	selectedBlockingInstances,
	onClick,
	pageSize,
}: BlockingInstancesTableProps) => {
	const [pageIndex, setPageIndex] = useSessionStorageState<number>(
		`${context}_BLOCKING_INSTANCE_PAGE_INDEX`,
		initialState.pageIndex,
	);
	const [selectedStatus, setSelectedStatus] = useSessionStorageState<BlockingInstanceStatus | 'ALL'>(
		`${context}_BLOCKING_INSTANCE_SELECTED_STATUS`,
		initialState.selectedStatus,
	);
	const [sortBy, setSortBy] = useSessionStorageState(`${context}_BLOCKING_INSTANCE_SORT_BY`, initialState.sortBy);
	const [sortDirection, setSortDirection] = useSessionStorageState<'asc' | 'desc'>(
		`${context}_BLOCKING_INSTANCE_SORT_DIRECTION`,
		initialState.sortDirection,
	);

	const nbInstances = useMemo(
		() => blockingInstances.filter((s) => (selectedStatus === 'ALL' ? true : s.status === selectedStatus)).length,
		[blockingInstances, selectedStatus],
	);

	const handleOrderBy = (orderByParam: string) => {
		if (orderByParam === sortBy) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortBy(orderByParam);
			setSortDirection('asc');
		}
	};

	const statuses = ['ALL', ...Object.values(BlockingInstanceStatus)];

	const initialIndex = statuses.findIndex((status) => status === selectedStatus);

	return (
		<Box>
			<Tabs
				variant="enclosed"
				isFitted
				isLazy
				bg="white"
				index={initialIndex}
				onChange={(index) => {
					setSelectedStatus(statuses[index] as BlockingInstanceStatus);
					setPageIndex(0);
				}}
			>
				{context === 'blocking-instance' && (
					<TabList
						h="60px"
						overflowY="hidden"
						borderWidth="2px"
						borderColor="gray.100"
						sx={{ scrollbarWidth: 'none', '::-webkit-scrollbar': { display: 'none' } }}
					>
						{statuses.map((status) => (
							<Tab
								key={status}
								_selected={{
									borderRadius: '0px',
									borderWidth: '2px',
									borderColor: 'gray.400',
								}}
							>
								{status}
							</Tab>
						))}
					</TabList>
				)}
				<TabPanels>
					{statuses.map((status) => (
						<TabPanel key={status} px="0px" py="0px">
							<TableContainer bg="white">
								<Table variant="simple" size="sm">
									<Thead>
										<Tr>
											<DefaultTh>Email</DefaultTh>
											<DefaultTh>Nom</DefaultTh>
											<DefaultTh>Prénom</DefaultTh>
											<DefaultTh>Produit</DefaultTh>
											<SortTh
												onClick={handleOrderBy}
												value="amount"
												selectedSortBy={sortBy}
												sortDirection={sortDirection}
											>
												Montant
											</SortTh>
											<DefaultTh>Black</DefaultTh>
											<DefaultTh>Partner</DefaultTh>
											<DefaultTh>Lifecycle</DefaultTh>
											<DefaultTh>Statut</DefaultTh>
											<DefaultTh>Mail Client</DefaultTh>
											<DefaultTh>Mail Partner</DefaultTh>

											<SortTh
												onClick={handleOrderBy}
												value="createdAt"
												selectedSortBy={sortBy}
												sortDirection={sortDirection}
											>
												Créé le
											</SortTh>
											<SortTh
												onClick={handleOrderBy}
												value="updatedAt"
												selectedSortBy={sortBy}
												sortDirection={sortDirection}
											>
												MAJ le
											</SortTh>
											<SortTh
												onClick={handleOrderBy}
												value="statusUpdatedAt"
												selectedSortBy={sortBy}
												sortDirection={sortDirection}
											>
												MAJ Statut
											</SortTh>
											<SortTh
												onClick={handleOrderBy}
												value="sentToClientAt"
												selectedSortBy={sortBy}
												sortDirection={sortDirection}
											>
												Envoyé le
											</SortTh>
											<DefaultTh>Respo</DefaultTh>
										</Tr>
									</Thead>

									<Tbody>
										{blockingInstances
											.filter((s) => (status === 'ALL' ? true : s.status === status))
											.filter((s) => (context === 'client' ? s.status !== BlockingInstanceStatus.CLOSED : true))
											.sort((a, b) => sortFields(a, b, sortBy, sortDirection))
											.slice(pageIndex, pageIndex + pageSize)
											.map((bi) => (
												<ConditionalLink
													key={bi.id}
													style={{ display: 'contents' }}
													to={`/ops/super/blocking-instance/${bi.id}`}
													shouldrenderLink={bi.status !== BlockingInstanceStatus.OPEN}
												>
													<Tr
														cursor="pointer"
														bg={rowColor(bi, selectedBlockingInstances)}
														onClick={() => {
															if (bi.status === BlockingInstanceStatus.OPEN && onClick) onClick(bi);
														}}
													>
														<DefaultTd>{bi.user?.email}</DefaultTd>
														<DefaultTd>{bi.user?.kyc?.lastName}</DefaultTd>
														<DefaultTd>{bi.user?.kyc?.firstName}</DefaultTd>
														<DefaultTd>{bi.product}</DefaultTd>
														<DefaultTd>{displayMoney(getProductAmount(bi.linkedEntity, bi.product))}</DefaultTd>
														<BoolTd value={bi.user?.isBlack} />
														<DefaultTd>{bi.partner}</DefaultTd>
														<DefaultTd>{bi.lifecycle}</DefaultTd>
														<DefaultTd>{bi.status}</DefaultTd>
														<BoolTd value={bi.hasBeenSentToClient} />
														<BoolTd value={bi.hasBeenSentToPartner} />
														<DateTd value={new Date(bi.createdAt)} />
														<DateTd value={new Date(bi.updatedAt)} />
														<DateTd value={getUpdatedStatusTime(bi)} />
														<DefaultTd>{getDateDistance(bi.sentToClientAt)} jours</DefaultTd>
														<DefaultTd>
															{bi?.opsProperties?.assignedOpsEmail?.split('@')[0].replace('.', ' ')}
														</DefaultTd>
													</Tr>
												</ConditionalLink>
											))}
									</Tbody>
								</Table>
							</TableContainer>
						</TabPanel>
					))}
				</TabPanels>
			</Tabs>

			{context !== 'client' && (
				<Box w="100%" textAlign="right" mb="16px" my="16px" fontWeight="semibold" userSelect="none">
					<Text as="span" color="gray.600">
						Show {pageIndex + 1} to {pageIndex + pageSize} of {nbInstances} entries{' '}
					</Text>
					<ChevronLeftIcon
						mx="16px"
						boxSize="24px"
						bg="gray.100"
						cursor="pointer"
						onClick={() =>
							setPageIndex(pageIndex === 0 ? Math.floor(nbInstances / pageSize) * 10 : pageIndex - pageSize)
						}
					/>
					<ChevronRightIcon
						boxSize="24px"
						bg="gray.100"
						cursor="pointer"
						onClick={() => setPageIndex(pageIndex + 1 * pageSize >= nbInstances ? 0 : pageIndex + pageSize)}
					/>
				</Box>
			)}
		</Box>
	);
};

export default BlockingInstancesTable;
