import { FC, useCallback, useMemo, useState } from 'react';
import { Button, Input, Select, Text, useToast, VStack } from '@chakra-ui/react';

import AlertDialog from 'components/AlertDialog';
import RequiredDocsUpload, { DocumentToUpload } from 'components/RequiredDocsUpload';
import { AllDeal, useChurnDealMutation } from 'services/deal';
import { ProductType } from 'types/global.type';
import { DocumentName } from 'utils/documentNamingMap';

const defaultFiles: DocumentToUpload = {
	attestationExit: { content: null, label: "Attestation de fin d'opération" },
};

type DealChurnModalProps = {
	deal: AllDeal;
	isOpen: boolean;
	onClose: () => void;
};

const DealChurnModal: FC<DealChurnModalProps> = ({ deal, isOpen, onClose }) => {
	const toast = useToast();
	const [churnDeal, { isLoading: isChurnLoading }] = useChurnDealMutation();

	const [churnReason, setChurnReason] = useState<'churn' | 'expiration'>('churn');
	const [exitValuation, setExitValuation] = useState('');
	const [attestationExit, setAttestationExit] = useState<DocumentToUpload>(defaultFiles);

	const doesNeedAttestationExit = useMemo(
		() =>
			churnReason === 'expiration' &&
			(deal.productType === ProductType.CROWDFUNDING || deal.productType === ProductType.ART),
		[churnReason, deal.productType],
	);

	const handleChurn = useCallback(() => {
		const formData = new FormData();
		formData.append('productType', deal.productType);
		formData.append('churnReason', churnReason);
		if (doesNeedAttestationExit) {
			formData.append('file', attestationExit.attestationExit.content![0]);
			formData.append('userId', deal.user.id);
			formData.append('documentName', DocumentName.TERMINATION_ATTESTATION);
			formData.append('exitValuation', exitValuation);
		}

		churnDeal({ id: deal.id, formData })
			.unwrap()
			.then(() => {
				toast({ status: 'success', title: 'Deal churn avec succès' });
				onClose();
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	}, [deal, churnReason, doesNeedAttestationExit, attestationExit, exitValuation, churnDeal, onClose, toast]);

	const handleCloseChurn = useCallback(() => {
		setAttestationExit(defaultFiles);
		setExitValuation('');
		onClose();
	}, [setAttestationExit, setExitValuation, onClose]);

	return (
		<AlertDialog
			isOpen={isOpen}
			onClose={onClose}
			header="Churn le deal"
			body={
				<VStack spacing="16px" align="start">
					<Text>Vous êtes sur le point de churn ce deal. Merci de saisir la raison.</Text>
					<Select value={churnReason} onChange={(e) => setChurnReason(e.target.value as 'churn' | 'expiration')}>
						<option value="churn">Churn</option>
						<option value="expiration">Expiration</option>
					</Select>
					{doesNeedAttestationExit && (
						<>
							<RequiredDocsUpload
								files={attestationExit}
								addFiles={(key, value) => {
									setAttestationExit((prevFiles) => ({
										...prevFiles,
										[key]: { content: value, label: prevFiles[key].label },
									}));
								}}
							/>
							<Input
								w="100%"
								placeholder="Exit Valuation"
								value={exitValuation}
								onChange={(e) => setExitValuation(e.target.value)}
							/>
						</>
					)}
				</VStack>
			}
			footer={
				<>
					<Button onClick={handleCloseChurn}>Annuler</Button>
					<Button
						colorScheme="red"
						ml={3}
						isLoading={isChurnLoading}
						onClick={handleChurn}
						isDisabled={churnReason === 'expiration' && (!exitValuation || !attestationExit)}
					>
						Valider
					</Button>
				</>
			}
		/>
	);
};

export default DealChurnModal;
