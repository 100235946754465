import { useForm } from 'react-hook-form';
import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	Select,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import AlertDialog from 'components/AlertDialog';
import useThemedToast from 'hooks/useThemedToast';
import { BoLinkPromotionType, UpdatePromotion, useUpdatePromotionMutation } from 'services/client';
import { emailPattern } from 'utils/functions';

type UpdateSponsorshipProps = {
	email: string;
};

const UpdatePromotionForm = ({ email }: UpdateSponsorshipProps) => {
	const toast = useThemedToast();
	const { isOpen: isValidationOpen, onOpen: openValidation, onClose: closeValidation } = useDisclosure();

	const [updatePromotion, { isLoading: isUpdateLoading }] = useUpdatePromotionMutation();

	const {
		formState: { errors, isValid },

		register,
		handleSubmit,
		watch,
	} = useForm<UpdatePromotion>({
		shouldUnregister: true,
		mode: 'onChange',
		defaultValues: {
			promotionType: BoLinkPromotionType.email,
			emailSponsorToLink: '',
			sponsorshipCode: '',
		},
	});

	const [promotionType, emailParrain, sponsorshipCode] = watch([
		'promotionType',
		'emailSponsorToLink',
		'sponsorshipCode',
	]);

	const onSubmit = handleSubmit((data) => {
		updatePromotion({ ...data, emailClient: email })
			.unwrap()
			.then(() => {
				toast({
					title: 'Promotion mise à jour',
					description: `La promotion du client ${email} a bien été mise à jour`,
					status: 'success',
				});
				closeValidation();
			})
			.catch((err) => {
				const errMessage = err.data?.message;
				toast({
					title: 'Erreur',
					description:
						errMessage || `Une erreur est survenue lors de la mise à jour de la promotion du client ${email}`,
					status: 'error',
				});
			});
	});

	return (
		<VStack align="start" w="100%" spacing="16px">
			<Heading size="sm">Activer une promotion pour ce client</Heading>

			<FormControl isInvalid={!!errors.promotionType}>
				<FormLabel>Que souhaitez-vous renseigner ?</FormLabel>
				<Select
					{...register('promotionType', {
						required: 'Ce champ est requis',
					})}
				>
					<option value={BoLinkPromotionType.email}>L'email du parrain</option>
					<option value={BoLinkPromotionType.code}>Un code promotionnel</option>
				</Select>
				{errors.promotionType?.type === 'required' && <FormErrorMessage>Ce champ est requis</FormErrorMessage>}
			</FormControl>

			{promotionType === BoLinkPromotionType.email && (
				<FormControl isInvalid={!!errors.emailSponsorToLink}>
					<FormLabel>Quel est l'email du parrain de ce client ?</FormLabel>
					<Input
						{...register('emailSponsorToLink', {
							required: 'Ce champ est requis',
							pattern: emailPattern,
						})}
					/>
					{errors.emailSponsorToLink?.type === 'required' && <FormErrorMessage>Ce champ est requis</FormErrorMessage>}
					{errors.emailSponsorToLink?.type === 'pattern' && (
						<FormErrorMessage>Cet email n'est pas valide</FormErrorMessage>
					)}
				</FormControl>
			)}

			{promotionType === BoLinkPromotionType.code && (
				<FormControl isInvalid={!!errors.sponsorshipCode}>
					<FormLabel>A quel code de promotion souhaitez-vous associer ce client ?</FormLabel>
					<Input
						{...register('sponsorshipCode', {
							required: 'Ce champ est requis',
						})}
					/>
					{errors.sponsorshipCode?.type === 'required' && <FormErrorMessage>Ce champ est requis</FormErrorMessage>}
				</FormControl>
			)}

			<Button isDisabled={!isValid} onClick={openValidation} colorScheme="blue">
				Valider
			</Button>

			<AlertDialog
				isOpen={isValidationOpen}
				onClose={closeValidation}
				header="Modifier l'email du client"
				body={
					<Text>
						Vous êtes sur le point de mettre à jour l'offre de bienvenue du client <b>{email}</b> :
						<br />
						{promotionType === BoLinkPromotionType.email ? (
							<>
								<br />
								Son parrain sera : <b>{emailParrain}</b>
							</>
						) : (
							<>
								<br />
								Code promotionnel : <b>{sponsorshipCode}</b>
							</>
						)}
						<br />
						Êtes-vous sûr de vouloir continuer ?
					</Text>
				}
				footer={
					<>
						<Button onClick={() => closeValidation()}>Annuler</Button>
						<Button colorScheme="red" onClick={onSubmit} ml={3} isLoading={isUpdateLoading}>
							Valider
						</Button>
					</>
				}
			/>
		</VStack>
	);
};

export default UpdatePromotionForm;
