import { FC, ReactNode, useMemo } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Box,
	Tab,
	Table,
	TableContainer,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Tbody,
	Text,
	Thead,
	Tr,
} from '@chakra-ui/react';

import { ConditionalLink } from 'components/tables/columns/ConditionalLink';
import { BoolTd, ButtonTd, DateTd, DefaultTd } from 'components/tables/columns/Td';
import { DefaultTh, SortTh } from 'components/tables/columns/Th';
import { isDealOverdue } from 'features/ChangeOverdueDate';
import { useNextStatusModal } from 'hooks/useNextStatusModal';
import useSessionStorageState from 'hooks/useSessionStorate';
import { AllDeal } from 'services/deal';
import { OpsProcess } from 'types/airtable/global.airtable.type';
import { StatusLabel } from 'types/airtable/status-label.airtable.type';
import { BOContext, LegalEntity, ProductType, SubscriptionStatus } from 'types/global.type';
import { VehiculeCodeInvest } from 'types/invest-subscription.type';
import { emailToOps, opsDisplayName } from 'types/ops-list.type';
import { displayMoney, getUpdatedStatusTime, isNone, isNotNone } from 'utils/functions';

//
// DISPLAY
//

const getVehiculeType = (productCodePartner: string): string => {
	switch (productCodePartner) {
		case 'RAMV':
			return 'AV RAMIFY';
		case 'RAMP':
			return 'PER RAMIFY';
		case 'P2':
			return 'AV INTENCIAL';
		case 'SP08':
			return 'AV CAPI';
		case 'IL':
			return 'PER INTENCIAL';
		case 'SV09':
			return 'AV HYBRIDE';
		default:
			return 'UNKNOWN';
	}
};

const getProductDetails = (deal: AllDeal): string => {
	switch (deal.productType) {
		case ProductType.SCPI:
		case ProductType.PE:
		case ProductType.CROWDFUNDING:
			return `${deal.productType} - ${deal.fundName} - ${deal.fundName}`;

		case ProductType.CASH:
			return `${deal.type} - ${deal.legalEntity}`;

		case ProductType.GIRARDIN:
		case ProductType.AV_LUX:
			return `${deal.productType}`;

		case ProductType.ART:
			return `${deal.productType} - ${deal.fundName}`;

		case ProductType.INVEST:
			if (deal.dealType === 'TRANSFER') return 'INVEST - PER - TRANSFER';
			if (deal.dealType === 'CONTRACT' && deal.structuredProduct.length > 0) return 'PRODUIT STRUCTURE - AV';
			if (deal.envelope.partnerCode === VehiculeCodeInvest.GENERALI_AV) return 'AV 100% Fonds euro Generali';

			// invest deal
			const productType = deal.productType;
			const vehicule = getVehiculeType(deal.envelope?.partnerCode);
			const dealType = deal.dealType;
			const risk = deal.investmentPreferences?.risk ?? '?';
			const projectType = 'subscription' in deal ? deal.subscription?.projectType : deal.projectType;
			const timeHorizon = 'subscription' in deal ? deal.subscription?.timeHorizon : deal.timeHorizon;
			const finalStr = `${productType} - ${vehicule} - ${dealType} - ${projectType} - ${risk}R`;
			return timeHorizon
				? `${finalStr} - ${new Date(timeHorizon).getFullYear() - new Date().getFullYear()} ANS`
				: finalStr;

		default:
			return 'PRODUIT INCONNU';
	}
};

const getProductSupport = (deal: AllDeal): OpsProcess | undefined => {
	switch (deal.productType) {
		case ProductType.SCPI:
		case ProductType.PE:
		case ProductType.CROWDFUNDING:
		case ProductType.ART:
			return deal.process;
		case ProductType.INVEST:
		case ProductType.CASH:
			return 'api';
		case ProductType.GIRARDIN:
		case ProductType.AV_LUX:
			return 'extranet';
		default:
			return undefined;
	}
};

export const getDealAmount = (deal: AllDeal): number | undefined => {
	switch (deal.productType) {
		case ProductType.CASH:
		case ProductType.SCPI:
		case ProductType.PE:
		case ProductType.CROWDFUNDING:
		case ProductType.ART:
		case ProductType.GIRARDIN:
		case ProductType.AV_LUX:
			return deal.initialDepositAmount ? +deal.initialDepositAmount : undefined;
		case ProductType.INVEST:
			if (deal.dealType === 'SUBSCRIPTION') return +deal.initialDepositAmount;
			if (deal.dealType === 'TRANSFER') return deal.transferAmount;
			if (deal.dealType === 'CONTRACT') return +deal.amount;
		default:
			return undefined;
	}
};

const getParcoursCompletionDate = (deal: AllDeal) => {
	let completionDate;

	if ('process' in deal && deal.process !== 'mail') completionDate = deal.pendingAt;
	else if (deal.productType === ProductType.INVEST && deal.dealType === 'TRANSFER') return null;
	else if (deal.productType === ProductType.INVEST && deal.dealType === 'CONTRACT') return null;
	else if (deal.productType === ProductType.GIRARDIN) return null;
	else if (deal.productType === ProductType.AV_LUX) completionDate = deal.pendingAt;
	else completionDate = deal.signedAt;

	if (!completionDate) return null;
	return new Date(completionDate);
};

const getLegalEntity = (deal: AllDeal): string =>
	'legalEntity' in deal && isNotNone(deal.legalEntity) ? deal.legalEntity : LegalEntity.PHYSICAL;

const getMoralPersonDenomination = (deal: AllDeal): string | undefined => {
	const legalEntity = getLegalEntity(deal);
	if (legalEntity === LegalEntity.PHYSICAL) return undefined; // deal is not PM
	if (!('moralPersonId' in deal && deal.moralPersonId)) return '⚠️'; // PM is not correctly linked to the deal

	const moralPerson = deal.user?.moralPersons?.find((mp) => mp.id === deal.moralPersonId);
	if (isNone(moralPerson)) return '⚠️'; // moralPersonId points to a non-existing PM
	return moralPerson.denomination || 'Sans denomination'; // no name
};

const rowColor = (deal: AllDeal, selectedDeal?: AllDeal, statuses?: Record<string, StatusLabel>): string => {
	if (selectedDeal?.id === deal.id) return 'blue.50';
	if (isNone(statuses)) return 'white';
	if (isDealOverdue(deal, statuses)) return 'red.50';
	return 'white';
};

export const isCallDue = (deal: AllDeal): boolean => {
	const statusesWithCallObligation = [
		SubscriptionStatus.KYC_VALIDATION,
		SubscriptionStatus.CONTRACT_SENT_TO_CLIENT,
		SubscriptionStatus.SIGNED,
		SubscriptionStatus.PARTNER_VALIDATION,
		SubscriptionStatus.PAYMENT,
	];

	if (deal.opsProperties?.isCallDone) return false;
	if (!statusesWithCallObligation.includes(deal.status)) return false;
	if (!deal.user.isBlack) return false;

	return true;
};

//
// SORTING
//

type DealWithAdditionalData = AllDeal & {
	opsProcess: OpsProcess | undefined;
	statusUpdatedAt: Date | null;
	statusCompletedAt: Date | null;
	dealLegalEntity: string;
	moralPersonDenomination: string | undefined;
	productDetail: string;
	dealAmount: number | undefined;
};

const sortFields = (a: DealWithAdditionalData, b: DealWithAdditionalData, orderBy: string, order: 'asc' | 'desc') => {
	if (orderBy === 'dealAmount') {
		const aAmount = +(a.dealAmount ?? 0);
		const bAmount = +(b.dealAmount ?? 0);
		if (order === 'asc') return aAmount - bAmount;
		else return bAmount - aAmount;
	}
	if (orderBy === 'statusUpdatedAt') {
		const aDate = a.statusUpdatedAt ? new Date(a.statusUpdatedAt).getTime() : 0;
		const bDate = b.statusUpdatedAt ? new Date(b.statusUpdatedAt).getTime() : 0;
		if (order === 'asc') return aDate - bDate;
		else return bDate - aDate;
	}
	if (orderBy === 'status') {
		const aStatus = a.status;
		const bStatus = b.status;
		const statuses = Object.keys(SubscriptionStatus);
		if (order === 'asc') return statuses.indexOf(aStatus) - statuses.indexOf(bStatus);
		else return statuses.indexOf(bStatus) - statuses.indexOf(aStatus);
	}
	return 0;
};

//
// UTILS
//

// dirty trick to just put link over regular td, with no button inside. https://stackoverflow.com/a/6393863/10889054
const TdLink = ({
	deal,
	shouldrenderLink,
	children,
	context,
}: {
	deal: AllDeal;
	shouldrenderLink: boolean;
	children: ReactNode;
	context: DealsTableProps['context'];
}) => (
	<ConditionalLink
		style={{ display: 'contents' }}
		to={`/ops/super/${context === 'subscription' ? 'subscription' : 'deal'}/${deal.id}?productType=${
			deal.productType === ProductType.INVEST && deal.dealType === 'TRANSFER' ? 'TRANSFER' : deal.productType
		}`}
		shouldrenderLink={shouldrenderLink}
		children={children}
	/>
);

//
// COMPONENT
//

type DealsTableProps = {
	context: Extract<BOContext, 'client' | 'subscription' | 'deal' | 'blocking-instance'>;
	deals: AllDeal[];
	selectedDeal?: AllDeal;
	onClick?: (c: AllDeal) => void;
	pageSize: number;
	productTypeStatuses?: Record<string, StatusLabel>;
};

const initialState = {
	pageIndex: 0,
	selectedStatus: 'ALL' as const,
	sortBy: 'statusUpdatedAt',
	sortDirection: 'desc' as const,
};

const DealsTable: FC<DealsTableProps> = ({ deals, selectedDeal, onClick, pageSize, context, productTypeStatuses }) => {
	const [pageIndex, setPageIndex] = useSessionStorageState<number>(
		`${context}_DEAL_PAGE_INDEX`,
		initialState.pageIndex,
	);
	const [selectedStatus, setSelectedStatus] = useSessionStorageState<SubscriptionStatus | 'ALL'>(
		`${context}_DEAL_SELECTED_STATUS`,
		initialState.selectedStatus,
	);
	const [sortBy, setSortBy] = useSessionStorageState(`${context}_DEAL_SORT_BY`, initialState.sortBy);
	const [sortDirection, setSortDirection] = useSessionStorageState<'asc' | 'desc'>(
		`${context}_DEAL_SORT_DIRECTION`,
		initialState.sortDirection,
	);

	const dealWithAdditionalData = deals.map((deal) => ({
		...deal,
		opsProcess: getProductSupport(deal),
		statusUpdatedAt: getUpdatedStatusTime(deal),
		statusCompletedAt: getParcoursCompletionDate(deal),
		dealLegalEntity: getLegalEntity(deal),
		moralPersonDenomination: getMoralPersonDenomination(deal),
		productDetail: getProductDetails(deal),
		dealAmount: getDealAmount(deal),
		isCallDue: isCallDue(deal),
	}));

	const { NextStatusModal, handleOpenNextStatusModal } = useNextStatusModal();

	const nbDeals = useMemo(
		() => deals.filter((s) => (selectedStatus === 'ALL' ? true : s.status === selectedStatus)).length,
		[deals, selectedStatus],
	);

	const handleOrderBy = (orderByParam: string) => {
		if (orderByParam === sortBy) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortBy(orderByParam);
			setSortDirection('asc');
		}
	};

	const statuses = [
		'ALL',
		...Object.values(SubscriptionStatus).filter((status) => status !== SubscriptionStatus.PARTNER_TREATMENT),
	];
	const initialIndex = statuses.findIndex((status) => status === selectedStatus);

	return (
		<Box>
			<NextStatusModal />

			<Tabs
				variant="enclosed"
				isFitted
				isLazy
				bg="white"
				index={initialIndex}
				onChange={(index) => {
					setSelectedStatus(statuses[index] as SubscriptionStatus);
					setPageIndex(0);
				}}
			>
				{context === 'deal' && (
					<TabList
						h="60px"
						overflowY="hidden"
						borderWidth="2px"
						borderColor="gray.100"
						sx={{ scrollbarWidth: 'none', '::-webkit-scrollbar': { display: 'none' } }}
					>
						{statuses.map((status) => (
							<Tab
								key={status}
								_selected={{
									borderRadius: '0px',
									borderWidth: '2px',
									borderColor: 'gray.400',
								}}
							>
								{status}
							</Tab>
						))}
					</TabList>
				)}

				<TabPanels>
					{statuses.map((status) => (
						<TabPanel key={status} px="0px" py="0px">
							<TableContainer bg="white">
								<Table variant="simple" size="sm">
									<Thead>
										<Tr>
											<DefaultTh>Email</DefaultTh>
											<DefaultTh>Nom</DefaultTh>
											<DefaultTh>Prénom</DefaultTh>
											<DefaultTh>Black</DefaultTh>
											<DefaultTh>PM</DefaultTh>
											<DefaultTh>Société</DefaultTh>
											<DefaultTh>Produit</DefaultTh>
											<DefaultTh>Partenaire</DefaultTh>
											<DefaultTh hide={context === 'subscription'}>OPS</DefaultTh>
											<SortTh
												onClick={handleOrderBy}
												value="dealAmount"
												selectedSortBy={sortBy}
												sortDirection={sortDirection}
											>
												Montant
											</SortTh>
											<SortTh
												onClick={handleOrderBy}
												value="status"
												selectedSortBy={sortBy}
												sortDirection={sortDirection}
											>
												Statut
											</SortTh>
											<DefaultTh>Changer statut</DefaultTh>
											<SortTh
												onClick={handleOrderBy}
												value="statutsUpdatedAt"
												selectedSortBy={sortBy}
												sortDirection={sortDirection}
											>
												MAJ Statut le
											</SortTh>
											<DefaultTh>Envoi</DefaultTh>
											<DefaultTh hide={context === 'subscription'}>CFCAL 1er deposit</DefaultTh>
											<DefaultTh>Complété le</DefaultTh>
											<DefaultTh>Instance</DefaultTh>
											<DefaultTh>Call ToDo</DefaultTh>
										</Tr>
									</Thead>

									<Tbody>
										{dealWithAdditionalData
											.filter((s) => (status === 'ALL' ? true : s.status === status))
											.sort((a, b) => sortFields(a, b, sortBy, sortDirection))
											.slice(pageIndex, pageIndex + pageSize)
											.map((deal) => (
												<Tr
													key={deal.id}
													cursor="pointer"
													bg={rowColor(deal, selectedDeal, productTypeStatuses)}
													onClick={() => {
														if (onClick) onClick(deal);
													}}
												>
													<TdLink deal={deal} shouldrenderLink={!onClick} context={context}>
														<DefaultTd>{deal.user?.email}</DefaultTd>
														<DefaultTd>{deal.user?.kyc?.lastName}</DefaultTd>
														<DefaultTd>{deal.user?.kyc?.firstName}</DefaultTd>
														<BoolTd value={deal.user?.isBlack} />
														<BoolTd value={deal.dealLegalEntity === LegalEntity.MORAL} />
														<DefaultTd>{deal.moralPersonDenomination}</DefaultTd>
														<DefaultTd>{deal.productDetail}</DefaultTd>
														<DefaultTd>{deal.partner}</DefaultTd>
														<DefaultTd hideCell={context === 'subscription'}>
															{opsDisplayName[emailToOps[deal.opsProperties?.assignedOpsEmail ?? '']]}
														</DefaultTd>
														<DefaultTd>{displayMoney(deal.dealAmount)}</DefaultTd>
														<DefaultTd>{deal.status}</DefaultTd>
													</TdLink>
													<ButtonTd
														onClick={(event) => {
															event.stopPropagation();
															handleOpenNextStatusModal(deal);
														}}
														buttonStyleProps={{
															isDisabled:
																(context === 'subscription' &&
																	(deal.opsProcess === 'mail' || deal.opsProcess === 'api')) ||
																[SubscriptionStatus.COMPLETED, SubscriptionStatus.TERMINATED].includes(deal.status),
															w: '100%',
															fontSize: '14px',
															h: '20px',
														}}
													>
														Suivant
													</ButtonTd>
													<TdLink deal={deal} shouldrenderLink={!onClick} context={context}>
														<DateTd value={deal.statusCompletedAt} />
														<DefaultTd>{deal.opsProcess}</DefaultTd>
														<BoolTd
															hideCell={context === 'subscription'}
															value={'didInitialDeposit' in deal ? deal.didInitialDeposit : undefined}
														/>
														<DateTd value={deal.statusUpdatedAt} />
														<BoolTd value={deal.hasBlockingInstance} />
														<BoolTd value={deal.isCallDue} />
													</TdLink>
												</Tr>
											))}
									</Tbody>
								</Table>
							</TableContainer>
						</TabPanel>
					))}
				</TabPanels>
			</Tabs>

			{context !== 'client' && (
				<Box w="100%" textAlign="right" my="16px" fontWeight="semibold" userSelect="none">
					<Text as="span" color="gray.600">
						Show {pageIndex + 1} to {pageIndex + pageSize} of {nbDeals} entries
					</Text>
					<ChevronLeftIcon
						mx="16px"
						boxSize="24px"
						bg="gray.100"
						cursor="pointer"
						onClick={() => setPageIndex(pageIndex === 0 ? Math.floor(nbDeals / pageSize) * 10 : pageIndex - pageSize)}
					/>
					<ChevronRightIcon
						boxSize="24px"
						bg="gray.100"
						cursor="pointer"
						onClick={() => setPageIndex(pageIndex + 1 * pageSize >= nbDeals ? 0 : pageIndex + pageSize)}
					/>
				</Box>
			)}
		</Box>
	);
};

export default DealsTable;
