import { useForm } from 'react-hook-form';
import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Input,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import AlertDialog from 'components/AlertDialog';
import useThemedToast from 'hooks/useThemedToast';
import { useUpdatePromotionDateMutation } from 'services/client';
import { displayDateSimpleFormat } from 'utils/rendering';

type UpdateSponsorshipProps = {
	email: string;
};

const UpdatePromotionDate = ({ email }: UpdateSponsorshipProps) => {
	const toast = useThemedToast();
	const { isOpen: isValidationOpen, onOpen: openValidation, onClose: closeValidation } = useDisclosure();

	const [updatePromotionDate, { isLoading: isUpdateLoading }] = useUpdatePromotionDateMutation();

	const {
		formState: { errors, isValid },
		register,
		handleSubmit,
		watch,
	} = useForm<{ newStartDate: Date }>({
		shouldUnregister: true,
		defaultValues: {
			newStartDate: new Date(),
		},
	});

	const [newStartDate] = watch(['newStartDate']);

	const onSubmit = handleSubmit((data) => {
		updatePromotionDate({ emailClient: email, promotionStartDate: new Date(data.newStartDate) })
			.unwrap()
			.then(() => {
				toast({
					title: 'Promotion mise à jour',
					description: `La promotion du client ${email} a bien été mise à jour`,
					status: 'success',
				});
				closeValidation();
			})
			.catch((err) => {
				const errMessage = err.data?.message;
				toast({
					title: 'Erreur',
					description:
						errMessage || `Une erreur est survenue lors de la mise à jour de la promotion du client ${email}`,
					status: 'error',
				});
			});
	});

	return (
		<VStack align="start" w="100%">
			<Heading size="sm">Mettre à jour la date de début de l'offre</Heading>

			<FormControl isInvalid={!!errors.newStartDate}>
				<FormLabel>A quelle date souhaitez-vous faire démarrer l'offre de bienvenue de ce client ?</FormLabel>
				<HStack>
					<Input
						type="date"
						{...register('newStartDate', {
							required: 'Veuillez renseigner une date',
							validate: (date) => {
								// Must not be more than 1 year ago and not in the future
								const oneYearAgo = new Date();
								oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
								if (date < oneYearAgo) {
									return 'La date ne peut pas être antérieure à un an';
								}
								if (date > new Date()) {
									return 'La date ne peut pas être dans le futur';
								}
							},
						})}
					/>
					<Button isDisabled={!isValid} colorScheme="blue" onClick={openValidation}>
						Valider
					</Button>
				</HStack>
				{errors.newStartDate && <FormErrorMessage>{errors.newStartDate.message}</FormErrorMessage>}
			</FormControl>

			<AlertDialog
				isOpen={isValidationOpen}
				onClose={closeValidation}
				header="Modification du début de l'offre de bienvenue"
				body={
					<Text>
						Vous êtes sur le point de mettre à jour l'offre de bienvenue du client <b>{email}</b> :
						<br />- La date de début de l'offre sera <b>{displayDateSimpleFormat(newStartDate)}</b>
						<br />
						Êtes-vous sûr de vouloir continuer ?
					</Text>
				}
				footer={
					<>
						<Button onClick={() => closeValidation()}>Annuler</Button>
						<Button colorScheme="red" onClick={onSubmit} ml={3} isLoading={isUpdateLoading}>
							Valider
						</Button>
					</>
				}
			/>
		</VStack>
	);
};

export default UpdatePromotionDate;
