import { useNavigate, useParams } from 'react-router-dom';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	Button,
	Center,
	Heading,
	HStack,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Skeleton,
	Text,
	useClipboard,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import AlertDialog from 'components/AlertDialog';
import Cardlayout from 'components/CardLayout';
import CustomModal from 'components/Modal';
import BankAccountList from 'features/bank-account';
import UpdateSponsorship from 'features/client-tools/Sponsorship/ClientSponsorshipModal';
import UpdateBlackStatus from 'features/client-tools/UpdateBlackStatus';
import UpdateEmail from 'features/client-tools/UpdateEmail';
import UpdateInvestorCategory from 'features/client-tools/UpdateInvestorCategory';
import DocumentsList from 'features/DocumentsList';
import UserDataTable from 'features/UserDataTable';
import useThemedToast from 'hooks/useThemedToast';
import BlockingInstanceList from 'pages/ops/super/blocking-instance/BlockingInstanceList';
import DealList from 'pages/ops/super/deal/DealList';
import SubscriptionList from 'pages/ops/super/subscription/SubscriptionList';
import TransferList from 'pages/ops/super/transfer/TransferList';
import {
	useDeleteClientMutation,
	useGetClientByIdQuery,
	useGetClientKYCQuery,
	useLazyGetAutologinTokenQuery,
} from 'services/client';
import { ramifyStacks } from 'utils/constants';
import { isNone } from 'utils/functions';
import { PermissionDisplayGuard } from 'utils/guards';
import { BoPermission } from 'utils/permissions';

export const ClientDetails = () => {
	const navigate = useNavigate();
	const toast = useThemedToast();
	const { id } = useParams<{ id: string }>();

	const { data: client, isFetching: isClientFetching } = useGetClientByIdQuery(id ?? '', { skip: isNone(id) });
	const { data: kyc } = useGetClientKYCQuery({ email: client?.email ?? '' }, { skip: isNone(client) });

	const [getAutologin] = useLazyGetAutologinTokenQuery();
	const [deleteClient, { isLoading }] = useDeleteClientMutation();

	const { onCopy } = useClipboard(client?.email ?? '');

	const { isOpen: isUpdateEmailOpen, onOpen: onOpenUpdateEmail, onClose: onCloseUpdateEmail } = useDisclosure();
	const { isOpen: isDeleteClientOpen, onOpen: onOpenDeleteClient, onClose: onCloseDeleteClient } = useDisclosure();
	const {
		isOpen: isUpdateInvestorCategoryOpen,
		onOpen: onOpenUpdateInvestorCategory,
		onClose: onCloseUpdateInvestorCategory,
	} = useDisclosure();
	const {
		isOpen: isUpdateBlackStatusOpen,
		onOpen: onOpenUpdateBlackStatus,
		onClose: onCloseUpdateBlackStatus,
	} = useDisclosure();
	const {
		isOpen: isUpdateParrainageOpen,
		onOpen: onOpenUpdateParrainage,
		onClose: onCloseUpdateParrainage,
	} = useDisclosure();

	const handleAutologin = async () => {
		getAutologin({ email: client!.email })
			.unwrap()
			.then(async (res) => {
				window.open(`${ramifyStacks.ramifyAppUrl.toString()}autologin?autologinToken=${res.token}`, '_blank');
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	const handleDelete = async () => {
		deleteClient({ email: client!.email })
			.unwrap()
			.then(() => {
				onCloseDeleteClient();
				navigate('..');
				toast({ status: 'success', title: 'Client supprimé avec succès' });
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	if (isClientFetching) return <Skeleton h="100%" w="100%" />;
	if (isNone(client))
		return (
			<Center>
				<Heading size="md">Client non trouvé</Heading>
			</Center>
		);

	return (
		<VStack w="100%" spacing="12px" align="start" pb="32px">
			<Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
				<BreadcrumbItem>
					<BreadcrumbLink onClick={() => navigate('..')}>Clients</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink
						onClick={() => {
							if (!client?.email) return;
							onCopy();
							toast({ status: 'info', title: 'Email copié dans le presse-papier' });
						}}
					>
						{client?.email}
					</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<HStack w="100%" align="start" justify="space-between">
				<Heading size="lg">
					{kyc?.kyc?.firstName} {kyc?.kyc?.lastName}
				</Heading>

				<HStack align="start">
					<Button colorScheme="blue" onClick={handleAutologin}>
						Autologin
					</Button>
					<Menu closeOnSelect={false}>
						<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
							Actions
						</MenuButton>
						<MenuList>
							<MenuItem
								as="a"
								href={`https://ramify.sentry.io/replays/?project=4505233079402496&query=user.email%3A${encodeURI(
									client.email,
								)}&statsPeriod=30d`}
								target="_blank"
							>
								Voir les sessions sentry
							</MenuItem>
							<MenuItem
								as="a"
								href={`https://ramify.sentry.io/issues/?environment=production&project=5870711&query=user.email%3A${encodeURI(
									client.email,
								)}&referrer=issue-list&statsPeriod=14d`}
								target="_blank"
							>
								Voir les erreurs sentry
							</MenuItem>

							<PermissionDisplayGuard permission={BoPermission.CLIENT_EMAIL_UPDATE}>
								<MenuItem onClick={onOpenUpdateEmail}>Modifier l'email</MenuItem>
							</PermissionDisplayGuard>
							<PermissionDisplayGuard permission={BoPermission.CLIENT_AFFILIATION_UPDATE}>
								<MenuItem onClick={onOpenUpdateParrainage}>Modifier le parrainage 🎁</MenuItem>
							</PermissionDisplayGuard>
							<PermissionDisplayGuard permission={BoPermission.CLIENT_BLACK_UPDATE}>
								<MenuItem onClick={onOpenUpdateBlackStatus}>Modifier le status black</MenuItem>
							</PermissionDisplayGuard>
							<PermissionDisplayGuard permission={BoPermission.CLIENT_INVESTOR_CATEGORY_UPDATE}>
								<MenuItem onClick={onOpenUpdateInvestorCategory}>Modifier la categorie d'investisseur</MenuItem>
							</PermissionDisplayGuard>
							<PermissionDisplayGuard permission={BoPermission.CLIENT_DELETE}>
								<MenuItem color="red.600" onClick={onOpenDeleteClient}>
									Supprimer le client
								</MenuItem>
							</PermissionDisplayGuard>
						</MenuList>
					</Menu>
				</HStack>
			</HStack>

			<VStack w="100%" spacing="12px" align="start">
				<UserDataTable email={client.email} />
				<DocumentsList context="client" email={client.email} />
				<Cardlayout title="Comptes Bancaires">
					<BankAccountList email={client.email} />
				</Cardlayout>
				<Cardlayout title="Souscriptions">
					<SubscriptionList context="client" customInput={client.email} />
				</Cardlayout>
				<Cardlayout title="Deals">
					<DealList context="client" customInput={client.email} />
				</Cardlayout>
				<Cardlayout title="Instances Bloquantes">
					<BlockingInstanceList context="client" customInput={client.email} />
				</Cardlayout>
				<Cardlayout title="Transfers PER">
					<TransferList context="client" customInput={client.email} />
				</Cardlayout>
			</VStack>

			<CustomModal
				isOpen={isUpdateEmailOpen}
				onClose={onCloseUpdateEmail}
				headersProps={{ children: "Modification de l'email du client" }}
			>
				<UpdateEmail email={client.email} />
			</CustomModal>

			<CustomModal
				isOpen={isUpdateParrainageOpen}
				onClose={onCloseUpdateParrainage}
				headersProps={{ children: 'Modification du parrainage du client' }}
			>
				<UpdateSponsorship email={client.email} id={client.id} />
			</CustomModal>

			<CustomModal
				isOpen={isUpdateBlackStatusOpen}
				onClose={onCloseUpdateBlackStatus}
				headersProps={{ children: 'Modification du statut black du client' }}
			>
				<UpdateBlackStatus email={client.email} />
			</CustomModal>

			<CustomModal
				isOpen={isUpdateInvestorCategoryOpen}
				onClose={onCloseUpdateInvestorCategory}
				headersProps={{ children: "Modification de la categorie d'investisseur du client" }}
			>
				<UpdateInvestorCategory email={client.email} />
			</CustomModal>

			<AlertDialog
				isOpen={isDeleteClientOpen}
				onClose={onCloseDeleteClient}
				header="Supprimer le client"
				body={
					<VStack spacing="16px" align="start">
						<Text>
							Vous êtes sur le point de supprimer ce client: <b>{client.email}</b>
						</Text>
					</VStack>
				}
				footer={
					<>
						<Button onClick={onCloseDeleteClient}>Annuler</Button>
						<Button colorScheme="red" ml="12px" isLoading={isLoading} onClick={handleDelete}>
							Valider
						</Button>
					</>
				}
			/>
		</VStack>
	);
};
