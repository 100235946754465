import { FC, useMemo } from 'react';
import { Divider, Text, VStack } from '@chakra-ui/react';

import UpdatePromotionDate from 'features/client-tools/Sponsorship/UpdatePromotionDate';
import UpdatePromotionForm from 'features/client-tools/Sponsorship/UpdatePromotionForm';
import { useGetClientPromotionQuery } from 'services/client';
import { displayDateSimpleFormat } from 'utils/rendering';

type UpdateSponsorshipProps = {
	email: string;
	id: string;
};

const ClientSponsorshipModal: FC<UpdateSponsorshipProps> = ({ email, id }) => {
	const { data: clientPromotion } = useGetClientPromotionQuery(id);

	const typeSponsorshipWording = useMemo(() => {
		if (!clientPromotion?.activated) return '';
		if (clientPromotion?.sponsorshipType === 'PARTNER') {
			return 'Affiliation';
		}
		if (clientPromotion?.sponsorshipType === 'AFFILIATE') {
			return 'Parrainage';
		}
		return 'Offre Globale';
	}, [clientPromotion]);

	const textExpiration = useMemo(() => {
		if (!clientPromotion?.activated) return null;
		if (clientPromotion?.isExpired) {
			return (
				<Text>
					❌{' '}
					{`L'offre de bienvenue de ce client a expiré le ${
						clientPromotion?.deadline ? displayDateSimpleFormat(clientPromotion.deadline) : '{Date non existante}'
					}`}
				</Text>
			);
		}
		return (
			<Text>
				✅ L'offre de bienvenue est <b>actuellement active</b> et expirera le{' '}
				{clientPromotion?.deadline ? displayDateSimpleFormat(clientPromotion.deadline) : '{Date non existante}'}
			</Text>
		);
	}, [clientPromotion]);

	return (
		<VStack align="start" spacing="32px">
			{clientPromotion?.activated ? (
				<VStack align="start" spacing="8px">
					<Text>Ce client a déjà activé une promotion</Text>
					{clientPromotion.sponsorshipType && (
						<Text>
							L'offre de bienvenue a été activé via {typeSponsorshipWording} par{' '}
							<b>{clientPromotion.by && `${clientPromotion.by}`}</b>
						</Text>
					)}
					{clientPromotion?.codeUsed && (
						<Text>
							Le code qui a été utilisé est <b>{clientPromotion.codeUsed}</b>
						</Text>
					)}
					{textExpiration}
				</VStack>
			) : (
				<Text>Ce client n'a pas encore activé son offre de bienvenue</Text>
			)}

			<Divider />

			{clientPromotion?.activated && <UpdatePromotionDate email={email} />}
			{clientPromotion?.activated === false && <UpdatePromotionForm email={email} />}
		</VStack>
	);
};

export default ClientSponsorshipModal;
