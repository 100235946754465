import { FC, useMemo, useRef, useState } from 'react';
import { Button, chakra, HStack, Input, Text, useDisclosure, VStack } from '@chakra-ui/react';

import { isNone, isNotNone } from 'utils/functions';

import YesNoIcon from './icons/YesNo';
import CustomModal from './Modal';

export type DocumentToUpload = Record<string, { label: string; content: FileList | null }>;

type RequiredDocsUploadProps = {
	files: DocumentToUpload;
	addFiles: (key: string, value: FileList) => void;
};

const RequiredDocsUpload: FC<RequiredDocsUploadProps> = ({ files, addFiles }) => {
	const { onToggle: onToggleFile, isOpen: isOpenFile } = useDisclosure();

	const inputFile = useRef<HTMLInputElement | null>(null);

	const [viewFile, setViewFile] = useState<string | null>(null);
	const viewFileContent = useMemo(
		() => (isNotNone(viewFile) && isNotNone(files) ? files[viewFile].content : null),
		[files, viewFile],
	);

	return (
		<VStack align="start" mt="16px">
			{Object.entries(files).map(([key, file]) => (
				<VStack key={key} align="start">
					<HStack>
						<Text
							_hover={isNotNone(file.content) ? { cursor: 'pointer', textDecoration: 'underline' } : undefined}
							onClick={() => {
								onToggleFile();
								setViewFile(key);
							}}
						>
							{file.label}
						</Text>
						<Input type="file" ref={inputFile} display="none" onChange={(e) => addFiles(key, e.target.files!)} />
						{isNone(file.content) ? (
							<Button onClick={() => inputFile.current?.click()}>Ajouter</Button>
						) : (
							<YesNoIcon isTrue />
						)}
					</HStack>
				</VStack>
			))}

			{isNotNone(viewFileContent) && (
				<CustomModal isOpen={isOpenFile} onClose={onToggleFile}>
					{viewFileContent[0]?.type.includes('image') ? (
						<chakra.img src={window.URL.createObjectURL(viewFileContent[0])} m="auto" />
					) : (
						<chakra.iframe src={window.URL.createObjectURL(viewFileContent[0])} w="100%" h="100%" />
					)}
				</CustomModal>
			)}
		</VStack>
	);
};

export default RequiredDocsUpload;
