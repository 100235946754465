import { Route, Routes } from 'react-router-dom';

import AlertDetails from './AlertDetail';
import AllAlertsPage from './AlertsList';

const AlertsRouter = (): JSX.Element => (
	<Routes>
		<Route path="*" element={<AllAlertsPage />} />
		<Route path="/:id" element={<AlertDetails />} />
	</Routes>
);

export default AlertsRouter;
