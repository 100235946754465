import { dashboardApi } from 'store/api';
import { OpsProperties } from 'types/ops-properties.type';

export type EditableOpsPropertiesFields = Partial<
	Pick<OpsProperties, 'id' | 'assignedOpsEmail' | 'overrideOverdueDate' | 'isCallDone'> & {
		conversationId?: string;
		comment?: string;
	}
>;

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getOpsPropertiesById: builder.query<OpsProperties, string>({
			query: (id: string) => ({
				url: `/bo/ops/properties/${id}`,
				method: 'GET',
			}),
			providesTags: ['OPS_PROPERTIES'],
		}),
		createComment: builder.mutation<OpsProperties, { id: string; content: string }>({
			query: ({ id, content }) => ({
				url: `/bo/ops/properties/${id}/comment`,
				method: 'POST',
				body: { content },
			}),
			invalidatesTags: ['OPS_PROPERTIES'],
		}),
		deleteComment: builder.mutation<OpsProperties, { id: string; commentId: string }>({
			query: ({ id, commentId }) => ({
				url: `/bo/ops/properties/${id}/comment`,
				method: 'DELETE',
				body: { commentId },
			}),
			invalidatesTags: ['OPS_PROPERTIES'],
		}),
	}),
});

export const { useGetOpsPropertiesByIdQuery, useCreateCommentMutation, useDeleteCommentMutation } = endpoints;
