import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { CheckIcon, DownloadIcon } from '@chakra-ui/icons';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Badge,
	Box,
	Button,
	Checkbox,
	Heading,
	HStack,
	Link as ChakraLink,
	Skeleton,
	Spinner,
	Table,
	TableCellProps,
	TableColumnHeaderProps,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	theme,
	Tr,
	VStack,
} from '@chakra-ui/react';
import fileDownload from 'js-file-download';

import useThemedToast from 'hooks/useThemedToast';
import OfferPaymentsPagination from 'pages/ops/offers/OfferPagination';
import { OpsOfferSubscriptionPayment, useCreateOfferPaymentsMutation } from 'services/ops/offers';
import { useMarkSponsorshipAsPaidMutation } from 'services/ops/sponsorship';
import { useAppSelector } from 'store';
import { displayMoney, toCSV } from 'utils/functions';
import { displayDateSimpleFormat } from 'utils/rendering';

const TableValue = ({ width, children, ...props }: TableCellProps) => (
	<Td overflowX="auto" borderColor="gray.300" minW={width} maxW={width} {...props}>
		{children}
	</Td>
);

const TableHeader = ({ children, ...props }: TableColumnHeaderProps) => (
	<Th borderColor="gray.300" {...props}>
		{children}
	</Th>
);

const OfferTable = ({
	isFetching,
	offerPaymentList,
	total,
}: {
	isFetching: boolean;
	offerPaymentList: OpsOfferSubscriptionPayment[];
	total: number;
}) => {
	const toast = useThemedToast();
	const { pagination } = useAppSelector((state) => state.offersTool);
	const pageSize = useMemo(() => pagination?.limit, [pagination]);
	const cancelRef = React.useRef();

	const [selectedOfferPayments, setSelectedOfferPayments] = useState<Map<string, boolean>>(new Map());
	const [markAsPaid, { isLoading: isMarkAsPaidLoading }] = useCreateOfferPaymentsMutation();
	const [showConfirmModal, setShowConfirmModal] = useState(false);

	const onSelectGlobal = () => {
		if (selectedOfferPayments.size > 0) {
			setSelectedOfferPayments(new Map());
		} else {
			const newSelectedPayments = new Map<string, boolean>();
			offerPaymentList.forEach((offerPayment) => {
				if (offerPayment.hasBeenPaid === false) newSelectedPayments.set(offerPayment?.id, true);
			});
			setSelectedOfferPayments(newSelectedPayments);
		}
	};

	const onSelect = (id: string) => {
		const newSelectedPayments = new Map(selectedOfferPayments);
		if (newSelectedPayments.has(id)) newSelectedPayments.delete(id);
		else newSelectedPayments.set(id, true);
		setSelectedOfferPayments(newSelectedPayments);
	};

	const isOfferSelected = (id: string) => !!selectedOfferPayments.get(id);

	const onExportCSV = () => {
		const extract: {
			beneficiary_name: string;
			iban: string;
			amount: number;
			currency: 'EUR';
			reference?: string;
		}[] = [];

		selectedOfferPayments.forEach((_, id) => {
			const offerPayment = offerPaymentList.find((ofp) => ofp.id === id);
			if (!offerPayment) {
				toast({
					status: 'error',
					title: 'Erreur',
					description: `Une erreur est survenue lors de l'export du fichier CSV, à cause du record ${id}. Veuillez vous rapprocher d'un tech`,
				});
				return;
			}
			if (offerPayment) {
				extract.push({
					beneficiary_name: offerPayment.subscription.user?.email || '',
					iban: offerPayment.bankAccount.IBAN || '',
					amount: offerPayment.amount,
					currency: 'EUR',
					reference: offerPayment.offerReference,
				});
			}
		});

		fileDownload(toCSV(extract), 'extract', 'text/csv');
	};

	const onMarkSelectedAsPaid = async () => {
		const offerPayments = offerPaymentList.filter((offerPayment) => isOfferSelected(offerPayment?.id));
		const paymentOffers = offerPayments.map((offerPayment) => ({
			reference: offerPayment.offerReference,
			amountPaid: offerPayment.amount,
		}));
		markAsPaid({ paymentOffers })
			.unwrap()
			.then(() => {
				setSelectedOfferPayments(new Map());
				setShowConfirmModal(false);
				toast({
					title: 'Les remboursements ont bien été marqués comme payés',
					status: 'success',
				});
			});
	};

	return (
		<VStack w="100%" align="start" spacing="24px">
			<AlertDialog
				isOpen={showConfirmModal}
				// @ts-ignore
				leastDestructiveRef={cancelRef}
				onClose={() => setShowConfirmModal(false)}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Marquer {selectedOfferPayments.size} remboursements comme payés
						</AlertDialogHeader>

						<AlertDialogBody>
							Êtes-vous sûr de vouloir marquer ces {selectedOfferPayments.size} remboursements comme payés ? Cette
							action est irréversible, assurez-vous d'avoir bien généré l'extract avant de procéder.
						</AlertDialogBody>

						<AlertDialogFooter>
							{/* @ts-ignore */}
							<Button ref={cancelRef} onClick={() => setShowConfirmModal(false)}>
								Annuler
							</Button>
							<Button colorScheme="red" onClick={onMarkSelectedAsPaid} ml={3}>
								Valider
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

			<HStack w="100%" justify="space-between">
				<Heading whiteSpace="nowrap" size="md" color={selectedOfferPayments.size > 0 ? 'blue.500' : 'gray.900'}>
					{selectedOfferPayments.size === 0
						? 'Remboursements à traiter'
						: `${selectedOfferPayments.size} remboursements sélectionnées`}
				</Heading>
				{selectedOfferPayments.size > 0 && (
					<HStack>
						<Button
							colorScheme="green"
							size="sm"
							isDisabled={selectedOfferPayments.size === 0 || isMarkAsPaidLoading}
							onClick={() => setShowConfirmModal(true)}
						>
							<HStack spacing="8px">
								<Text>Marquer comme payé</Text>
								{isMarkAsPaidLoading ? <Spinner size="xs" /> : <CheckIcon boxSize="12px" />}
							</HStack>
						</Button>
						<Button
							colorScheme="blue"
							size="sm"
							isDisabled={selectedOfferPayments.size === 0 || isMarkAsPaidLoading}
							onClick={onExportCSV}
						>
							<HStack spacing="8px">
								<Text>Exporter CSV</Text>
								<DownloadIcon boxSize="12px" />&
							</HStack>
						</Button>
					</HStack>
				)}

				<OfferPaymentsPagination totalOfferPayments={total} />
			</HStack>

			<TableContainer w="100%" bg="white" paddingTop="8px" borderRadius="8px" border="1px solid" borderColor="gray.300">
				<Table w="100%" variant="simple" size="md" __css={{ tableLayout: 'fixed' }}>
					<Thead w="100%">
						<Tr w="100%">
							<TableHeader w="60px">
								<Checkbox
									isIndeterminate={
										selectedOfferPayments.size > 0 && selectedOfferPayments.size !== offerPaymentList.length
									}
									isChecked={selectedOfferPayments.size === offerPaymentList.length && offerPaymentList.length > 0}
									onChange={() => onSelectGlobal()}
								/>
							</TableHeader>
							<TableHeader w="300px">Offre</TableHeader>
							<TableHeader w="300px">Client</TableHeader>
							<TableHeader w="100px">Status</TableHeader>
							<TableHeader w="300px">Souscription</TableHeader>
							<TableHeader w="100px">Montant à Payer</TableHeader>
							<TableHeader w="200px">Date limite de paiement</TableHeader>
						</Tr>
					</Thead>
					{!isFetching && (
						<Tbody w="100%">
							{offerPaymentList.map((offerPayment) => (
								<Tr
									w="100%"
									key={`${offerPayment?.offer?.id} - ${offerPayment?.subscription?.id}`}
									bg={
										isOfferSelected(offerPayment?.id)
											? 'blue.50 !important'
											: offerPayment.hasBeenPaid
											? 'green.50'
											: 'white'
									}
								>
									<TableValue
										cursor="pointer"
										onClick={(e) => {
											e.stopPropagation();
											if (offerPayment.hasBeenPaid === false) onSelect(offerPayment?.id);
										}}
									>
										{/* https://github.com/chakra-ui/chakra-ui/issues/2854 */}
										{offerPayment.hasBeenPaid === false && (
											<Box pointerEvents="none">
												<Checkbox isChecked={isOfferSelected(offerPayment?.id)} isReadOnly />
											</Box>
										)}
									</TableValue>

									<TableValue _hover={{ bg: theme.colors.gray[100] }} cursor="pointer">
										<ChakraLink
											target="_blank"
											href={`https://airtable.com/appwt4FPLHA1tgHXA/tbl0yjcOl0nqmJiR0/viwc8RhnsTC0QnW5T/${offerPayment?.offer?.id}`}
										>
											<Text>{offerPayment?.offer?.offerName}</Text>
										</ChakraLink>
									</TableValue>

									<TableValue _hover={{ bg: theme.colors.gray[100] }} cursor="pointer">
										<Link to={`/ops/super/client/${offerPayment.subscription.user?.id}`}>
											<Text>{offerPayment.subscription.user?.email}</Text>
										</Link>
									</TableValue>

									<TableValue>
										<Badge colorScheme={offerPayment?.hasBeenPaid ? 'green' : 'red'}>
											{offerPayment?.hasBeenPaid ? 'Payé' : 'Non Payé'}
										</Badge>
									</TableValue>

									<TableValue>
										<Link
											to={`/ops/super/deal/${offerPayment?.subscription?.id}?productType=${offerPayment?.subscription?.type}`}
										>
											<Badge colorScheme="gray" cursor="pointer" _hover={{ bg: theme.colors.gray[300] }}>
												{offerPayment?.subscription.type} - {offerPayment?.subscription?.productDetail}
											</Badge>
										</Link>
									</TableValue>

									<TableValue>
										<Text fontWeight="semibold">{displayMoney(offerPayment?.amount)}</Text>
									</TableValue>

									<TableValue>
										<VStack align="start">
											<Text>{displayDateSimpleFormat(offerPayment?.reimbursementDeadline)}</Text>
											<Text fontSize="xs" color="gray.500">
												Signé le {displayDateSimpleFormat(offerPayment?.date)}
											</Text>
										</VStack>
									</TableValue>
								</Tr>
							))}
						</Tbody>
					)}
				</Table>
				{isFetching && (
					<VStack w="100%" align="start" spacing="8px">
						{[...Array(pageSize > 0 ? pageSize : 5)].map((_, index) => (
							<Skeleton w="100%" h="60px" key={index.toString()} />
						))}
					</VStack>
				)}
			</TableContainer>
		</VStack>
	);
};

export default OfferTable;
