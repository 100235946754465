import { useCallback, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';

import NextStatusModal from 'features/NextStatusModal';
import { AllDeal } from 'services/deal';
import { isNotNone } from 'utils/functions';

export const useNextStatusModal = () => {
	const nextStatusModalMethods = useDisclosure();

	const [selectedDealForNextStatus, setSelectedDealForNextStatus] = useState<AllDeal | null>(null);

	const handleOpenNextStatusModal = useCallback(
		(deal: AllDeal) => {
			nextStatusModalMethods.onOpen();
			setSelectedDealForNextStatus(deal);
		},
		[nextStatusModalMethods],
	);

	const Modal = useCallback(
		() =>
			isNotNone(selectedDealForNextStatus) && nextStatusModalMethods.isOpen ? (
				<NextStatusModal {...nextStatusModalMethods} deal={selectedDealForNextStatus} />
			) : null,
		[nextStatusModalMethods, selectedDealForNextStatus],
	);

	return {
		nextStatusModalMethods,
		selectedDealForNextStatus,
		setSelectedDealForNextStatus,
		NextStatusModal: Modal,
		handleOpenNextStatusModal,
	};
};
