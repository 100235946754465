import { FC, useMemo } from 'react';
import { Avatar, Badge, Box, Card, HStack, Text, Tooltip, useDisclosure } from '@chakra-ui/react';

import Cardlayout from 'components/CardLayout';
import useProfilePicture from 'hooks/useProfilePicture';
import { HistoryBatch, HistoryLog, OpsProperties } from 'types/ops-properties.type';
import { isNone } from 'utils/functions';
import { displayDateInLetters } from 'utils/rendering';

const customPrefixKeys = [
	'comment',
	'delete-comment',
	'linkedEntityId',
	'assignedOps',
	'isCallDone-true',
	'isCallDone-false',
];

const logFieldLabels = (key: string) => {
	switch (key) {
		case 'status':
			return 'le statut';
		case 'hubspotId':
			return "l'ID Hubspot";
		case 'message':
			return 'le message';
		case 'overrideOverdueDate':
			return "la date d'échéance";
		// SPECIAL
		case 'assignedOps':
			return 'assigné un OPS';
		case 'comment':
			return 'commenté';
		case 'delete-comment':
			return 'supprimé un commentaire';
		case 'linkedEntityId':
			return 'associé une souscription';
		case 'isCallDone-true':
			return 'effectué un appel';
		case 'isCallDone-false':
			return "marqué l'appel comme non effectué";
		// DEFAULT
		default:
			return key;
	}
};

const LogDisplay: FC<{ log: HistoryLog }> = ({ log }) => {
	const tooLongValue = useMemo(() => log.value.length > 35, [log.value]);
	const isCustomPrefix = useMemo(() => customPrefixKeys.includes(log.key), [log.key]);

	return (
		<HStack p="3px" spacing="3px">
			{!isCustomPrefix && <Text>modifié</Text>}
			<Tooltip label={log.value.toString()}>
				<Text fontStyle="italic" textDecorationLine={tooLongValue ? 'underline' : 'none'}>
					{logFieldLabels(log.key)}
					{!tooLongValue && ': '}
				</Text>
			</Tooltip>
			{!tooLongValue && <Text fontWeight="bold">{log.value.toString()}</Text>}
		</HStack>
	);
};

const LogEntry: FC<{
	modifications: HistoryLog[];
	authorId: string;
	timestamp: string;
}> = ({ modifications, authorId, timestamp }) => {
	const profilePicture = useProfilePicture(authorId);
	const dateString = displayDateInLetters(timestamp);
	const { isOpen, onToggle } = useDisclosure();

	return (
		<Box
			px="4px"
			_hover={{ cursor: modifications.length > 1 ? 'pointer' : undefined, bg: 'gray.100', borderRadius: '8px' }}
			onClick={onToggle}
			key={timestamp}
		>
			<HStack fontSize="12px">
				<Text fontWeight="bold" minW="100px" textAlign="center">
					{dateString}
				</Text>
				<Tooltip label={authorId}>
					<Avatar size="xs" src={profilePicture} name={authorId} />
				</Tooltip>
				<HStack flexWrap="wrap">
					{isOpen ? (
						modifications.map((log) => (
							<Card key={`${timestamp}-${log.key}-${log.value}}`}>
								<LogDisplay log={log} />
							</Card>
						))
					) : (
						<>
							<Card>
								<LogDisplay log={modifications[0]} />
							</Card>
							<Box>{modifications.length > 1 && <Badge>+{modifications.length - 1}</Badge>}</Box>
						</>
					)}
				</HStack>
			</HStack>
		</Box>
	);
};

const HistorySection: FC<{ opsProperties?: OpsProperties }> = ({ opsProperties }) => {
	const batches = useMemo(() => opsProperties?.history.map((batch) => JSON.parse(batch)), [opsProperties]);

	if (isNone(opsProperties) || opsProperties.history.length === 0) return null;

	return (
		<Cardlayout title="Historique">
			{(batches as HistoryBatch[])?.map(
				(batch) => batch.modifications.length > 0 && <LogEntry key={batch.timestamp} {...batch} />,
			)}
		</Cardlayout>
	);
};

export default HistorySection;
